import * as React from 'react';
import Box from '@mui/material/Box';
import Navbar from '../../components/navbar'
import Sidebar from '../../components/sidebar'
import Footer from '../../components/footer'
import BackHandOutlinedIcon from '@mui/icons-material/BackHandOutlined';
import {useEffect,useState} from 'react'
import './order.css'
import CardComponent from './cardComponent'
import axios from 'axios';
import {useSelector } from "react-redux";
import {selectUserToken} from "../../store/authSlice";
import ErrorComponent from '../../components/errorComponet/errorComponent'

 const Orders = () => {

const [appointmentRequests,setAppointmentRequests]=useState([])
const [error,setError]=useState('')
const token = useSelector(selectUserToken);
useEffect(()=>{
    getAppoitmentRequests()
},[])

const customizeDataLevels = (data) => {
    const formattedData = data.map((item) => {
        const date = new Date(item.attributes.time);
        let hours = date.getHours();
        const ampm = hours >= 12 ? 'مساء' : 'صباحا';
        hours = hours % 12;
        hours = hours ? hours : 12;
        const min=('0' + date.getMinutes()).slice(-2); 

      return {
        id: item.id,
        name: item.attributes.name,
        sessionFullTime: item.attributes.time,
        time:`${hours}:${min}`,
        ampm:ampm,
        date: item.attributes.time.substring(0, 10),
        caseTypeName: item.attributes.case_type.data.attributes.name,
        caseTypeDescription: item.attributes.case_type.data.attributes.description,
        caseTypeID: item.attributes.case_type.data.id,
        userName: item.attributes.user.data.attributes.username,
        userEmail: item.attributes.user.data.attributes.email,
        userPhone: item.attributes.user.data.attributes.phone,
        user: item.attributes.user,
      };
    });
    return formattedData;
  };

const getAppoitmentRequests =async ()=>{
    try {
        const res = await axios.get(
          process.env.REACT_APP_API_URL +`/appointment-requests?populate=*&filters[action][$eq]=${"pending"}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setAppointmentRequests(customizeDataLevels(res.data.data));
      } catch (error) {
        setError("Error occured in get appoitments: "+error);
      }
}

if(error){
  return <ErrorComponent error={error}/>
}
    return (   
        <Box sx={{backgroundColor:'rgba(248, 250, 252, 0.7)' }}>
        <Box display="grid" gridTemplateColumns="repeat(11, 1fr)">
          <Box gridColumn="span 9"
           className="mx-3 mt-3">
            <Navbar/>
            {/* content */}
            <Box gridColumn="span 9" className="mt-4">
              <div dir='rtf' style={{textAlign: 'right', margin:'0% 0% 2% 0%'}}>
              <span className='me-1 fw-bold' style={{color:'rgba(36, 36, 36, 0.80)'}}>الطلبات</span>
              <BackHandOutlinedIcon sx={{color:'rgba(36, 36, 36, 0.80)'}} className='mb-2'/>
             
              </div>
            {appointmentRequests.map((appointmentRequest) => (
                    <CardComponent key={appointmentRequest.id} data={appointmentRequest}/>      
                ))}
          </Box>
          </Box>
          <Box gridColumn="span 2">
          <Sidebar/> 
          </Box>
          <Box gridColumn="span 11">
          <Footer/> 
          </Box>
         
         
          
        </Box>
      </Box>
    )
}

export default Orders; 
