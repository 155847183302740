import React, { useState, useEffect } from "react";
import {Container,Row,Col} from "react-bootstrap";
import { Box, IconButton } from "@mui/material";
import Navbar from "../../components/navbar";
import Sidebar from "../../components/sidebar";
import { Search } from "@mui/icons-material";
import Footer from "../../components/footer";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import { DataGrid } from "@mui/x-data-grid";
import { Form, Button, Modal,Toast,ToastContainer,Alert } from "react-bootstrap";
import closeIcon from "../../Assets/images/Close.png";
import {Avatar,ListItemAvatar,ListItemText,Typography,ListItem} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import profileImage from "../../Assets/images/profile placholder image.jpg";
import { useNavigate } from "react-router-dom";
import "./legalIssues.css";
import { useSelector } from "react-redux";
import {selectUserToken} from "../../store/authSlice";
import axios from "axios";
import ErrorComponent from '../../components/errorComponet/errorComponent'

const LegalIssues = () => {
  const [data, setdata] = useState([]);
  const [allData, setAllData] = useState([]);
  const [oldData, setOldData] = useState([]);
  const [statusData, setStatusData] = useState([]);
  const [caseTypeData, setCaseTypeData] = useState([]);
  const [caseBatchesData, setCaseBatchesData] = useState([]);
  const [caseTitle, setCaseTitle] = useState("");
  const [caseDescription, setCaseDescription] = useState("");
  const [caseTypeID, setCaseTypeID] = useState("");
  const [caseStateID, setCaseStateID] = useState("");
  const [caseFees, setCaseFees] = useState("");
  const [caseDeposite, setCaseDeposite] = useState("");
  const [caseBatchID, setCaseBatchID] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [emailValid, setEmailValid] = useState(true);
  const [caseDeletedID,setCaseDeletedID]=useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastValue, setToastValue] = useState("");
  const [toastColor, setToastColor] = useState("");
  const [toastIcon, setToastIcon] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  // for attachments feild
  const [inputValue, setInputValue] = useState("");
  const [attachments, setAttachments] = useState([]);
  const [searchValue, setSearchValue] = useState([]);
  const [error, setError] = useState("");
  const [creationError, setCreationError] = useState("");
  const [caseDate, setCaseDate] = useState("");
  const token = useSelector(selectUserToken);

  useEffect(() => {
    getCases();
    getcasesStatus();
    getcasesTypes();
    getCasesBatches();
  }, []);

  // this part handle add new issue modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCaseSubmitValidations = () => {
    if (
      caseBatchID === "" ||
      caseDeposite === "" ||
      caseDescription === "" ||
      caseDate === "" ||
      caseFees === "" ||
      userEmail === "" ||
      caseFees === "" ||
      caseTitle === "" ||
      caseTypeID === "" ||
      caseStateID === "" ||
      attachments.length === 0
    ) {
      setCreationError("برجاء ادخال جميع البيانات كامله");
    } else {
      setCreationError("");
      createCase(userEmail);
    }
  };
  const createCase = async (userEmail) => {
    try {
      const userInfo = await getUserByEmail(userEmail);
      if (userInfo.length === 0) {
        setCreationError("لا يوجد مستخدم بهذا الاسم");
      } else {
       await axios.post(
          `${process.env.REACT_APP_API_URL}/cases`,
          {
            data: {
              title: caseTitle,
              description: caseDescription,
              case_status: [caseStateID],
              case_type: [caseTypeID],
              user: [userInfo[0].id],
              nextSessionDate: caseDate,
              fees: caseFees,
              deposit: caseDeposite,
              case_batch: caseBatchID,
              attachments: attachments,
            },
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setShowToast(true);
        setToastValue("تم انشاء قضية جديده");
        setToastColor("success");
        setToastIcon(true);
      }
      setCaseTitle("");
      setCaseDescription("");
      setCaseStateID("");
      setCaseTypeID("");
      setCaseDate("");
      setCaseFees("");
      setCaseDeposite("");
      setCaseBatchID("");
      setAttachments([]);
    } catch (error) {
      setShowToast(true);
      setToastValue("يوجد مشكلة لم تتم عملسة اضافة قضية");
      setToastColor("danger");
      setToastIcon(false);
    }
  };
  // this part handle update new Issue modal
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const handleUpdateModalClose = () => setShowUpdateModal(false);
  const handleUpdateCase = async () => {
    try {
    await axios.put(
        `${process.env.REACT_APP_API_URL}/cases/${oldData[0].id}`,
        {
          data: {
            title: caseTitle,
            description: caseDescription,
            case_type: [caseTypeID],
            case_status: [caseStateID],
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setShowToast(true);
        setToastValue("تم تعديل القضية");
        setToastColor("success");
        setToastIcon(true);
    } catch (error) {
      setShowToast(true);
      setToastValue("يوجد مشكله لم يتم تعديل القضية");
      setToastColor("danger");
      setToastIcon(false);
    }
    setShowUpdateModal(false);
    window.location.reload();
  };
  const handleUpdateModalShow = (id) => {
    setShowUpdateModal(true);
    getCaseByID(id);
  };

  const handleDelete =(id) => {
    setShowAlert(true)
    setCaseDeletedID(id)
   
  };
  const deleteCase =async ()=>{
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/cases/${caseDeletedID}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setShowToast(true);
        setToastValue("تم مسح القضية");
        setToastColor("success");
        setToastIcon(true);
      window.location.reload();
    } catch (error) {
      setShowToast(true);
      setToastValue("يوجد مشكله لم يتم مسح القضية");
      setToastColor("danger");
      setToastIcon(false);
    }
  }

  const getCaseByID = async (id) => {
    try {
      const res = await axios.get(
        process.env.REACT_APP_API_URL +
          `/cases/${id}?populate[0]=case_type&populate[1]=user.image&populate[3]=case_status`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const resultData = customizeDataLevels([res.data.data]);
      setCaseTitle(resultData[0].caseTitle);
      setCaseDescription(resultData[0].description);
      setCaseTypeID(resultData[0].caseTypeID);
      setCaseStateID(resultData[0].caseState.data.id);
      setOldData([res.data.data]);
    } catch (error) {
      setError("Error occured in getting case details: "+error);
    }
  };

  const customizeDataLevels = (data) => {
    const formattedData = data.map((item) => {
      // condtion to handle user image if user not have image
      let userImage = item.attributes.user.data.attributes.image.data;
      if (userImage === null) {
        userImage = profileImage;
      } else {
        userImage =
          process.env.REACT_APP_URL + item.attributes.user.data.attributes.image.data.attributes.url;
      }
      return {
        id: item.id,
        caseTitle: item.attributes.title,
        description: item.attributes.description,
        caseDate: item.attributes.nextSessionDate.substring(0, 10),
        caseType: item.attributes.case_type.data.attributes.name,
        caseTypeID: item.attributes.case_type.data.id,
        caseState: item.attributes.case_status,
        userName: item.attributes.user.data.attributes.username,
        userEmail: item.attributes.user.data.attributes.email,
        user: item.attributes.user,
        userImage: userImage,
      };
    });
    return formattedData;
  };

  const getCases = async () => {
    try {
      const res = await axios.get(
        process.env.REACT_APP_API_URL +
          "/cases?populate[0]=case_type&populate[1]=user.image&populate[3]=case_status",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setdata(customizeDataLevels(res.data.data));
      setAllData(customizeDataLevels(res.data.data));
    } catch (error) {
      setError("Error occured in getting old cases: "+error);
      console.log("error: ",error)
    }
  };

  const getcasesStatus = async () => {
    try {
      const res = await axios.get(
        process.env.REACT_APP_API_URL + "/case-statuses",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setStatusData(res.data.data);
    } catch (error) {
      setError("Error occured in getting cases status: "+error);
    }
  };

  const getcasesTypes = async () => {
    try {
      const res = await axios.get(
        process.env.REACT_APP_API_URL + "/case-types",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setCaseTypeData(res.data.data);
    } catch (error) {
      setError("Error occured in getting cases types: "+error);
    }
  };
  const getCasesBatches = async () => {
    try {
      const res = await axios.get(
        process.env.REACT_APP_API_URL + "/case-payment-batches",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setCaseBatchesData(res.data.data);
    } catch (error) {
      setError("Error occured in getting cases batches: "+error);
    }
  };
  const getUserByEmail = async (email) => {
    try {
      const res = await axios.get(
        process.env.REACT_APP_API_URL + `/users?filters[email][$eq]=${email}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return res.data;
    } catch (error) {
      setCreationError("يوجد مشكله الرجال التاكد من بريد المستخدم");
    }
  };
  const DataFilteredByUserEmail = (email) => {
    if (email === "") {
      setdata(allData);
    } else {
      const filteredData = allData.filter((item) => item.userEmail === email);
      setdata(filteredData);
    }
  };

  const DataFilteredByCaseType = (caseType) => {
    if (caseType === "الكل") {
      setdata(allData);
    } else {
      const filteredData = allData.filter((item) => item.caseType === caseType);
      setdata(filteredData);
    }
  };
  const DataFilteredByCaseStatus = (caseStatus) => {
    if (caseStatus === "الكل") {
      setdata(allData);
    } else {
      const filteredData = allData.filter(
        (item) => item.caseState.data.attributes.name === caseStatus
      );
      setdata(filteredData);
    }
  };

  const columns = [
    {
      field: "user",
      headerName: "بينات العميل",
      width: 290,
      renderCell: (params) => {
        return (
          <>
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <Avatar alt="Remy Sharp" src={params.row.userImage} />
              </ListItemAvatar>
              <ListItemText
                sx={{
                  textAlign: "start",
                }}
                primary={
                  <Typography 
                    sx={{
                      fontSize: "15px",
                      fontWeight: "700",
                      fontFamily: "'Noto Sans Arabic', sans-serif",
                      color: "rgba(63, 63, 63, 0.8)",
                    }}
                    component="span"
                  >
                    {params.row.user.data.attributes.username}
                  </Typography>
                }
                secondary={
                  <React.Fragment>
                    <Typography
                      sx={{
                        color: "rgba(150, 149, 147, 0.8)",
                        fontSize: "11px",
                      }}
                      component="div"
                      variant="body2"
                    >
                      {params.row.user.data.attributes.email}
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItem>
          </>
        );
      },
    },
    {
      field: "caseTitle",
      headerName: "اسم القضيه",
      width: 150,
    },
    {
      field: "description",
      headerName: "وصف القضيه",
      width: 170,
    },
    {
      field: "caseType",
      headerName: " نوع القضيه  ",
      width: 170,
      renderCell: (params) => {
        return (
          <>
            <nav className="case-type">{params.row.caseType}</nav>
          </>
        );
      },
    },
    {
      field: "caseState",
      headerName: " حاله القضيه  ",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <nav
              className="case-status"
              style={{
                backgroundColor:
                  params.row.caseState.data.attributes.caseBoxColor,
                color: params.row.caseState.data.attributes.caseColor,
              }}
            >
              {params.row.caseState.data.attributes.name}
            </nav>
          </>
        );
      },
    },
    {
      field: "caseDate",
      headerName: "تاريخ البدء",
      width: 140,
    },

    {
      field: "action",
      headerName: "Action",
      width: 100,
      renderCell: (params) => {
        return (
          <>
            <IconButton
              onClick={() => handleUpdateModalShow(params.row.id)}
              sx={{ color: "#A87C2D" }}
            >
              {" "}
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={() => handleDelete(params.row.id)}
              sx={{ color: "#A87C2D" }}
            >
              {" "}
              <DeleteOutlineIcon />
            </IconButton>
          </>
        );
      },
    },
  ];

  const modalHeaderStyle = {
    fontWeight: "800",
    textAlign: "center",
    fontSize: "26rem",
    color: "rgb(78, 78, 78)",
  };
  const handleStatusChange = (event) => {
    DataFilteredByCaseStatus(event.target.value);
  };
  const handleCaseTypeChange = (event) => {
    DataFilteredByCaseType(event.target.value);
  };

  // handle attachments feild
  const handleChange2 = (event) => {
    setInputValue(event.target.value);
  };

  const handleSubmit = () => {
    if (inputValue.trim() !== "") {
      setAttachments([...attachments, { attachmentType: inputValue.trim() }]);
      setInputValue("");
    }
  };

  const handleRemove = (index) => {
    const updatedAttachments = [...attachments];
    updatedAttachments.splice(index, 1);
    setAttachments(updatedAttachments);
  };

  // handle email validation
  const handleEmailChange = (e) => {
    const email = e.target.value;
    setUserEmail(email);
    // Regular expression for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setEmailValid(emailRegex.test(email));
  };

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
    DataFilteredByUserEmail(event.target.value);
  };

  //handle navigate to case Details
  const navigate = useNavigate();
  const handleRowClick = (params) => {
    const id = params.id;
    navigate(`/caseDetails/${id}`);
  };
  if (error) {
    return  <ErrorComponent error={error}/>;
  }
  return (
    <div>
      <Box sx={{ backgroundColor: "rgba(248, 250, 252, 0.7)" ,padding:'0px',margin:'0px' }}>
        <Box
          display="grid"
          gridTemplateColumns="repeat(11, 1fr)"
        >
          <Box gridColumn="span 9" 
          className="mx-3 mt-3">
            <Navbar />
            <Box
              gridColumn="span 9"
           className="mt-4"
              dir="rtl"
            >
                 {/* alert message */}
            <Alert show={showAlert} variant="danger"   className="w-25"
                  style={{  right: "75%" }}>
              <Alert.Heading> انتبه !</Alert.Heading>
              <p>هل انت متاكد من مسح هذه القضية</p>
              <hr />
              <div className="d-flex justify-content-end">
                <Button
                  className="mx-2"
                  onClick={() => setShowAlert(false)}
                  variant="outline-secondary"
                >
                  الغاء
                </Button>
                <Button 
                onClick={deleteCase}
                 variant="outline-danger">
                  نعم
                </Button>
              </div>
            </Alert>

            {/* taost message */}
            <Row>
              <Col xs={12}>
                <ToastContainer
                  dir="rtl"
                  className="position-absolute"
                  style={{ top: "100px", left: "5px" }}
                >
                  <Toast
                    onClose={() => setShowToast(false)}
                    show={showToast}
                    delay={10000}
                    autohide
                    animation
                    bg={toastColor}
                  >
                    <Toast.Body className="text-white">
                      {toastIcon ? <CheckCircleIcon /> : <ErrorIcon />}
                      <span className="px-2 text-white fs-6">{toastValue}</span>
                    </Toast.Body>
                  </Toast>
                </ToastContainer>
              </Col>
            </Row>
              <div
                dir="rtf"
                className="text-end mb-4"
              
              >
                <FileCopyOutlinedIcon
                  sx={{ color: "rgba(36, 36, 36, 0.80)" }}
                />
                <span
                className="me-1 fs-bold "
                  style={{  color: "rgba(36, 36, 36, 0.80)"}}
                >
                  القضايه القانونيه
                </span>
              </div>
              {/* main div */}
              <div>
                <div>
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={1}
                  >
                    <Box
                      sx={{
                        gridColumn: {
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 4",
                          lg: "span 4",
                          xl: "span 3",
                        },
                      }}
                    >
                      <Button
                        className="w-75 mb-2 h-75 fs-5 d-flex justify-content-center align-items-center"
                        href="#"
                        style={{
                          backgroundColor: "#a87c2d",
                          borderColor: "#a87c2d",
                        }}
                        onClick={handleShow}
                      >
                        انشاء قضيه جديده +
                      </Button>

                      {/* this part to handel add issue part */}
                      <Modal
                        show={show}
                        onHide={handleClose}
                        backdrop="static"
                        keyboard={false}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                      >
                        <Modal.Body className="d-flex flex-row-reverse">
                          <img
                            src={closeIcon}
                            alt="close icon"
                            onClick={handleClose}
                            style={{
                              cursor: "pointer",
                              position: "absolute",
                              top: "-15px",
                              left: "-15px",
                            }}
                          />
                          <Container className="bg-white py-1 px-3 " dir="rtl">
                            <Row>
                              <Col
                                sm={12}
                                style={modalHeaderStyle}
                                className="mb-3"
                              >
                                <h1>اضافة قضية جديدة</h1>
                              </Col>
                              <Col sm={12}>
                                <Form.Group
                                  className="mb-3"
                                  controlId="exampleForm.ControlInput1"
                                >
                                  <Form.Label className="me-2">
                                    اختر البريد الالكتروني
                                  </Form.Label>
                                  <Form.Control
                                    type="email"
                                    placeholder="ايميل صاحب القضية"
                                    onChange={handleEmailChange}
                                    isInvalid={
                                      !emailValid && userEmail.length > 0
                                    }
                                  />
                                  {!emailValid && userEmail.length > 0 && (
                                    <Form.Control.Feedback type="invalid">
                                      الرجاء إدخال بريد إلكتروني صحيح
                                    </Form.Control.Feedback>
                                  )}
                                </Form.Group>
                              </Col>
                              <Col sm={12}>
                                <Form.Group
                                  className="mb-3"
                                  controlId="exampleForm.ControlInput1"
                                >
                                  <Form.Label className="me-2">
                                    اسم القضيه
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder=" اسم القضيه"
                                    onChange={(e) =>
                                      setCaseTitle(e.target.value)
                                    }
                                  />
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <select
                                  className="createSelect w-100"
                                  onChange={(e) =>
                                    setCaseTypeID(e.target.value)
                                  }
                                >
                                  {" "}
                                  <option value="" disabled selected>
                                    اختر نوع القضيه
                                  </option>
                                  {caseTypeData ? (
                                    caseTypeData.map((type) => (
                                      <option
                                        value={type.id}
                                        selected={type.id === caseTypeID}
                                      >
                                        {type.attributes.name}
                                      </option>
                                    ))
                                  ) : (
                                    <option value="0">loading...</option>
                                  )}
                                </select>
                              </Col>
                            </Row>
                            <Row className="mt-3">
                              <Col sm={12} md={4}>
                                <Form.Group
                                  className="mb-3"
                                  controlId="exampleForm.ControlInput1"
                                >
                                  <Form.Label className="me-2">
                                    اتعاب القضية الكلية
                                  </Form.Label>
                                  <Form.Control
                                    type="number"
                                    placeholder="المبلغ الكلى"
                                    style={{
                                      color: "rgba(130, 130, 130, 1)",
                                      "::placeholder": {
                                        color: "rgba(130, 130, 130, 1)",
                                      },
                                    }}
                                    onChange={(e) =>
                                      setCaseFees(e.target.value)
                                    }
                                  />
                                </Form.Group>
                              </Col>
                              <Col sm={12} md={4}>
                                <Form.Group
                                  className="mb-3"
                                  controlId="exampleForm.ControlInput1"
                                >
                                  <Form.Label className="me-2">
                                    مقدم الاتعاب
                                  </Form.Label>
                                  <Form.Control
                                    type="number"
                                    placeholder="مبلغ"
                                    style={{
                                      color: "rgba(130, 130, 130, 1)",
                                      "::placeholder": {
                                        color: "rgba(130, 130, 130, 1)",
                                      },
                                    }}
                                    onChange={(e) =>
                                      setCaseDeposite(e.target.value)
                                    }
                                  />
                                </Form.Group>
                              </Col>
                              <Col sm={12} md={4}>
                                <Form.Group
                                  className="mb-3"
                                  controlId="exampleForm.ControlInput1"
                                >
                                  <Form.Label className="me-2">
                                    تاريخ الجلسه القادمه
                                  </Form.Label>
                                  <Form.Control
                                    type="date"
                                    placeholder="مبلغ"
                                    style={{
                                      color: "rgba(130, 130, 130, 1)",
                                      "::placeholder": {
                                        color: "rgba(130, 130, 130, 1)",
                                      },
                                    }}
                                    onChange={(e) =>
                                      setCaseDate(e.target.value)
                                    }
                                  />
                                </Form.Group>
                              </Col>
                              <Col sm={12} md={6}>
                                <Form.Label className="me-2">
                                  دفعات القضيه
                                </Form.Label>
                                <select
                                  className="createSelect w-100"
                                  onChange={(e) =>
                                    setCaseBatchID(e.target.value)
                                  }
                                >
                                  <option value="" disabled selected>
                                    اختر الدفعه
                                  </option>
                                  {caseBatchesData ? (
                                    caseBatchesData.map((batch) => (
                                      <option
                                        value={batch.id}
                                        selected={batch.id === caseBatchID}
                                      >
                                        {batch.attributes.batchName}
                                      </option>
                                    ))
                                  ) : (
                                    <option value="0">loading...</option>
                                  )}
                                </select>
                              </Col>
                              <Col sm={12} md={6}>
                                <Form.Label className="me-2">
                                  حاله القضيه
                                </Form.Label>
                                <select
                                  className="createSelect"
                                  onChange={(e) =>
                                    setCaseStateID(e.target.value)
                                  }
                                >
                                  <option value="" disabled selected>
                                    اختر الحاله
                                  </option>
                                  {statusData ? (
                                    statusData.map((status) => (
                                      <option value={status.id}>
                                        {status.attributes.name}
                                      </option>
                                    ))
                                  ) : (
                                    <option value="0">loading...</option>
                                  )}
                                </select>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Form>
                                  <Form.Group
                                    className="mb-3"
                                    controlId="exampleForm.ControlInput1"
                                  >
                                    <Form.Label className="me-2">
                                      وصف القضيه
                                    </Form.Label>
                                    <Form.Control
                                      as="textarea"
                                      rows={3}
                                      placeholder="وصف"
                                      onChange={(e) =>
                                        setCaseDescription(e.target.value)
                                      }
                                    />
                                  </Form.Group>
                                </Form>
                              </Col>

                              <Col sm={12}>
                                <div className="me-2">
                                  الملفات
                                </div>

                                <div className="attachments-container ">
                                  <button
                                    className="attachments-btn"
                                    onClick={handleSubmit}
                                  >
                                    حفظ
                                  </button>
                                  <input
                                    type="text"
                                    value={inputValue}
                                    onChange={handleChange2}
                                    placeholder="اكتب الطلب"
                                    dir="rtl"
                                  />
                                </div>

                                <div className="attachments-name-container">
                                  {attachments.map((attachment, index) => (
                                    <div key={index}>
                                      <button
                                        onClick={() => handleRemove(index)}
                                      >
                                        x
                                      </button>

                                      {attachment.attachmentType}
                                    </div>
                                  ))}
                                </div>
                              </Col>
                              <Col sm={12}>
                                {creationError ? (
                                  <div className="text-danger">{creationError}</div>
                                ) : (
                                  <div></div>
                                )}
                              </Col>
                            </Row>
                          </Container>
                        </Modal.Body>
                        <Modal.Footer className="p-4">
                          <button
                            className="batch-cancel-btn rounded-2 px-2 py-3"
                            onClick={handleClose}
                          >
                            الغاء
                          </button>
                          <button
                            href="#"
                            className="batch-btn rounded-2 px-2 py-3 w-75"
                            onClick={handleCaseSubmitValidations}
                            type="submit"
                          >
                            حفظ
                          </button>
                        </Modal.Footer>
                      </Modal>
                    </Box>

                    <Box
                      sx={{
                        gridColumn: {
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                          xl: "span 5",
                        },
                      }}
                    >
                      <div className="d-flex w-100 h-100">
                        <div className="search-icon h-75 rounded-end">
                          <Search />
                        </div>
                        <input
                          type="text"
                          className="searchStyle w-100 h-75 rounded-start"
                          placeholder="بحث بالبريد الالكترونى..."
                          value={searchValue}
                          onChange={handleSearchChange}
                        />
                      </div>
                    </Box>

                    <Box
                      sx={{
                        gridColumn: {
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                          xl: "span 2",
                        },
                      }}
                    >
                      <div>
                        <span className="ms-2">نوع القضيه</span>
                        <select
                          className="filterSelect"
                          onChange={handleCaseTypeChange}
                        >
                          <option value="الكل" selected>
                            الكل
                          </option>
                          {caseTypeData ? (
                            caseTypeData.map((type) => (
                              <option value={type.attributes.name}>
                                {type.attributes.name}
                              </option>
                            ))
                          ) : (
                            <option value="0">loading...</option>
                          )}
                        </select>
                      </div>
                    </Box>
                    <Box
                      sx={{
                        gridColumn: {
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                          xl: "span 2",
                        },
                      }}
                    >
                      <div>
                        <span className="ms-2">حاله القضيه</span>
                        <select
                          className="filterSelect"
                          onChange={handleStatusChange}
                        >
                          <option value="الكل" selected>
                            الكل
                          </option>
                          {statusData ? (
                            statusData.map((status) => (
                              <option value={status.attributes.name}>
                                {status.attributes.name}
                              </option>
                            ))
                          ) : (
                            <option value="0">loading...</option>
                          )}
                        </select>
                      </div>
                    </Box>
                  </Box>
                </div>
                <div className="my-3">
                  <DataGrid
                    rows={data}
                    columns={columns}
                    initialState={{
                      pagination: {
                        paginationModel: {
                          pageSize: 20,
                        },
                      },
                    }}
                    pageSizeOptions={[20]}
                    checkboxSelection
                    disableRowSelectionOnClick
                    className="fs-6 fw-medium"
                    // onRowClick={handleRowClick}
                    onCellClick={(params, event) => {
                      const isActionColumn = params.field === 'action';
                  
                      if (!isActionColumn) {
                        handleRowClick(params, event);
                      }
                    }}
                  />
                </div>

                {/* {-------update modal-------} */}
                <Modal show={showUpdateModal} onHide={handleUpdateModalClose}>
                  <Modal.Header closeButton>
                    <Modal.Title className="modal-title ">
                      تعديل القضيه
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Row dir="rtl">
                      <Col sm={12}>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label className="me-2">
                            اسم القضيه
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder={caseTitle}
                            onChange={(e) => setCaseTitle(e.target.value)}
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={12}>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlTextarea1"
                        >
                          <Form.Label>وصف القضيه</Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder={caseDescription}
                            onChange={(e) => setCaseDescription(e.target.value)}
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={12} className="mt-4">
                        <Form.Label>نوع القضيه</Form.Label>
                        <select
                          className="updateSelect w-100"
                          onChange={(e) => setCaseTypeID(e.target.value)}
                        >
                          {caseTypeData ? (
                            caseTypeData.map((type) => (
                              <option
                                value={type.id}
                                selected={type.id === caseTypeID}
                              >
                                {type.attributes.name}
                              </option>
                            ))
                          ) : (
                            <option value="0">loading...</option>
                          )}
                        </select>
                      </Col>
                      <Col sm={12} className="my-4">
                        <Form.Label>حاله القضيه</Form.Label>
                        <select
                          className="updateSelect w-100"
                          onChange={(e) => setCaseStateID(e.target.value)}
                        >
                          {statusData ? (
                            statusData.map((status) => (
                              <option
                                value={status.id}
                                selected={status.id === caseStateID}
                              >
                                {status.attributes.name}
                              </option>
                            ))
                          ) : (
                            <option value="0">loading...</option>
                          )}
                        </select>
                      </Col>
                    </Row>
                  </Modal.Body>
                  <Modal.Footer>
                    <button
                      className="close-btn"
                      onClick={handleUpdateModalClose}
                    >
                      الغاء
                    </button>
                    <button className="save-btn" onClick={handleUpdateCase}>
                      حفظ
                    </button>
                  </Modal.Footer>
                </Modal>
              </div>
            </Box>
          </Box>
          <Box gridColumn="span 2">
            <Sidebar />
          </Box>
          <Box gridColumn="span 12">
            <Footer />
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default LegalIssues;
