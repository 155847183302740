import React, { useState } from "react";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Form, Button, Modal } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import closeIcon from "../../Assets/images/Close.png";

const CaseBatchs = () => {
  const [creationError, setCreationError] = useState("");
  const [show, setShow] = useState(false);

  const handleCaseSubmitValidations = () => {};
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const modalHeaderStyle = {
    fontWeight: "800",
    textAlign: "center",
    fontSize: "26rem",
    color: "rgb(78, 78, 78)",
  };
  return (
    <section className="container bg-white p-4">
      <div className="case-info-container ">
        <div className="case-info-title px-4 mb-4">الدفعات السابقه</div>
        <div className="px-4">
          <div className="border border-2 border-secondary py-2 px-4">
            <div className="row">
              <div className="col-6 col-lg-3 fw-bold">المبلغ الكلي</div>
              <div className="col-6 col-lg-3 fw-bold">2500 $</div>
              <div className="col-6 col-lg-3 fw-bold">المطلوب سداده</div>
              <div className="col-6 col-lg-3 fw-bold">200 $</div>
            </div>
          </div>
          <table class="table border">
            <thead class="table-secondary">
              <tr>
                <th scope="col">رقم الدفعة</th>
                <th scope="col">تاريخ الدفع</th>
                <th scope="col">وسيلة الدفع</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">1</th>
                <td>Mark</td>
                <td>Otto</td>
                <td>
                  <DeleteOutlineIcon sx={{ color: "rgba(36, 36, 36, 0.7)" }} />
                </td>
              </tr>
              <tr>
                <th scope="row">2</th>
                <td>Jacob</td>
                <td>Thornton</td>
                <td>
                  <DeleteOutlineIcon sx={{ color: "rgba(36, 36, 36, 0.7)" }} />
                </td>{" "}
              </tr>
              <tr>
                <th scope="row">3</th>
                <td colspan="2">Larry the Bird</td>
                <td>
                  <DeleteOutlineIcon sx={{ color: "rgba(36, 36, 36, 0.7)" }} />
                </td>{" "}
              </tr>
            </tbody>
          </table>

          <button className="batch-btns rounded-2 px-4 py-2 mt-4" onClick={handleShow}>
            اضافة دفعة
          </button>

          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Body
              style={{
                display: "flex",
                flexDirection: "row-reverse",
              }}
            >
              <img
                src={closeIcon}
                alt="close icon"
                onClick={handleClose}
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  top: "-15px",
                  left: "-15px",
                }}
              />
              <Container className="bg-white py-1 px-3 " dir="rtl">
                <Row>
                  <Col sm={12} style={modalHeaderStyle} className="mb-3">
                    <h1>اضافة قضية جديدة</h1>
                  </Col>
                  <Col sm={12}>
                    <select
                      className="createSelect w-100"
                      // onChange={(e) =>
                      //   setCaseTypeID(e.target.value)
                      // }
                    >
                      {" "}
                      <option value="" disabled selected>
                        اختر نوع القضيه
                      </option>
                      {/* {caseTypeData ? (
                                    caseTypeData.map((type) => (
                                      <option
                                        value={type.id}
                                        selected={type.id === caseTypeID}
                                      >
                                        {type.attributes.name}
                                      </option>
                                    ))
                                  ) : (
                                    <option value="0">loading...</option>
                                  )} */}
                    </select>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col sm={12} md={4}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label className="me-2">
                        اتعاب القضية الكلية
                      </Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="المبلغ الكلى"
                        style={{
                          color: "rgba(130, 130, 130, 1)",
                          "::placeholder": {
                            color: "rgba(130, 130, 130, 1)",
                          },
                        }}
                        // onChange={(e) =>
                        //   setCaseFees(e.target.value)
                        // }
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={12} md={4}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label className="me-2">مقدم الاتعاب</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="مبلغ"
                        style={{
                          color: "rgba(130, 130, 130, 1)",
                          "::placeholder": {
                            color: "rgba(130, 130, 130, 1)",
                          },
                        }}
                        // onChange={(e) =>
                        //   setCaseDeposite(e.target.value)
                        // }
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={12} md={4}>
                    <Form.Label className="me-2">دفعات القضيه</Form.Label>
                    <select
                      className="createSelect w-100"
                      // onChange={(e) =>
                      //   setCaseBatchID(e.target.value)
                      // }
                    >
                      <option value="" disabled selected>
                        اختر الدفعه
                      </option>
                      {/* {caseBatchesData ? (
                                    caseBatchesData.map((batch) => (
                                      <option
                                        value={batch.id}
                                        selected={batch.id === caseBatchID}
                                      >
                                        {batch.attributes.batchName}
                                      </option>
                                    ))
                                  ) : (
                                    <option value="0">loading...</option>
                                  )} */}
                    </select>
                  </Col>
                </Row>
              </Container>
            </Modal.Body>
            <Modal.Footer className="p-4">
              <button
                className="batch-cancel-btn rounded-2 px-2 py-3"
                onClick={handleClose}
              >
                الغاء
              </button>
              <button
                href="#"
                className="batch-btn rounded-2 px-2 py-3 w-75"
                onClick={handleCaseSubmitValidations}
                type="submit"
              >
                حفظ
              </button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </section>
  );
};

export default CaseBatchs;
