import {
  Form,
  InputGroup,
  Button,
  Toast,
  ToastContainer,
  Row,
  Col,
} from "react-bootstrap";
import LockIcon from "@mui/icons-material/Lock";
import EmailIcon from "@mui/icons-material/Email";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import React, { useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { login } from "../../store/authSlice";

import "./login.css";

const Login = () => {
  const EmailInputStyle = {
    width: "74%",
    height: "60px",
    borderRadius: "5px",
    borderTopLeftRadius: "0",
    borderBottomLeftRadius: "0",
  };
  const inputStyle = {
    width: "74%",
    height: "60px",
    borderRadius: "0px",
  };
  const inputStyle3 = {
    color: "#979494",
    flexShrink: 0,
    height: "60px",
    borderTopLeftRadius: "0",
    borderBottomLeftRadius: "0",
  };
  const inputStyle2 = {
    color: "#979494",
    flexShrink: 0,
    height: "60px",
    borderTopRightRadius: "0",
    borderBottomRightRadius: "0",
  };
  const placeholderStyle = { color: "A9A9AC" };
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastValue, setToastValue] = useState("");
  const [toastColor, setToastColor] = useState("");
  const [toastIcon, setToastIcon] = useState(true);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmition = (e) => {
    e.preventDefault();
    if (email === "" && password === "") {
      setError("Invalid email or password");
    } else {
      setError("");
      handleLogin();
    }
  };

  const handleLogin = async () => {
    await axios
      .post(process.env.REACT_APP_API_URL + "/auth/local", {
        identifier: email,
        password: password,
      })
      .then((response) => {
        dispatch(login(response.data.jwt));
        setShowToast(true);
        setToastValue("تمت عملية الدخول اللى حسابك");
        setToastColor("success");
        setToastIcon(true);
      })
      .catch((error) => {
        setShowToast(true);
        setToastValue(" لم تتم العملية بنجاح ");
        setToastColor("danger");
        setToastIcon(false);
        console.log(error)
      });
  };

  return (
    <div className="dimensions">
      {/* Toast Message */}
      <Row>
        <Col xs={12}>
          <ToastContainer
            dir="rtl"
            className="position-absolute"
            style={{ top: "50px", right: "15px" }}
          >
            <Toast
              onClose={() => setShowToast(false)}
              show={showToast}
              delay={10000}
              autohide
              animation
              bg={toastColor}
            >
              <Toast.Body className="text-white">
                {toastIcon ? <CheckCircleIcon /> : <ErrorIcon />}
                <span className="px-2 text-white fs-6">{toastValue}</span>
              </Toast.Body>
            </Toast>
          </ToastContainer>
        </Col>
      </Row>

      <form onSubmit={handleSubmition} className="w-100 h-100">
        <div className="container">
          <div className="login dimensions  d-flex flex-column justify-content-center align-items-center">
            <div className="login-wrapper rounded-4 p-1">
              <div className="header">
                <div className="text">Login</div>
                <div className="line"></div>
              </div>

              <div className="inputs">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label style={{ color: "#5a5959" }}>
                    Email address
                  </Form.Label>
                  <InputGroup>
                    <InputGroup.Text
                      style={{ color: "#979494" }}
                      className=" m-0"
                    >
                      <EmailIcon />
                    </InputGroup.Text>
                    <Form.Control
                      type="email"
                      placeholder="name@example.com"
                      style={{ ...EmailInputStyle, ...placeholderStyle }}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </InputGroup>
                </Form.Group>

                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput2"
                >
                  <Form.Label style={{ color: "#5a5959" }}>Password</Form.Label>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <InputGroup.Text
                      className="m-0 border-end-0"
                      style={inputStyle2}
                    >
                      <LockIcon />
                    </InputGroup.Text>
                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      placeholder="Enter your password"
                      style={inputStyle}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <InputGroup.Text className="m-0 " style={inputStyle3}>
                      <span onClick={togglePasswordVisibility}>
                        {showPassword ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </span>
                    </InputGroup.Text>
                  </div>
                </Form.Group>
              </div>
              <div className="d-flex justify-content-center align-itmes-center">
                <Button
                  variant="primary"
                  style={{
                    background: "#a87c2d",
                    borderColor: "#a87c2d",
                    
                    fontWeight: "bolder",
                  }}
                  className="px-4 py-2 mt-3"
                  type="submit"
                >
                  Login
                </Button>
              </div>
              {error && <p className="fw-seibold fs-6 text-danger">{error}</p>}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Login;
