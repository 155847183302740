import {configureStore} from "@reduxjs/toolkit"
import authSlice from "./authSlice"
import userSilce from './userDataSlice'

const store =configureStore({
    reducer: {
        auth: authSlice,
        user:userSilce,
    }

})

export default store