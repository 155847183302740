import React from 'react'
import {Route, Routes } from "react-router-dom";
import HomePage from './pages/Home/home'
import ErrorPage from './pages/errorPage/errorPage'
import Orders from './pages/orders/order'
import LegalIssues from './pages/legal-issues/legalIssues'
import Login from './pages/Login/login'
import CaseName from './pages/cases-names/casesName'
import WorkDays from './pages/workDays/workDays'
import Appointments from './pages/appointments/appointments'
import Users from "./pages/users/users"
import Posts from './pages/posts/posts'
import AddPost from './pages/posts/addPost'
import CaseDetails from './pages/legal-issues/caseDetails'
import AuthLayout from './components/authLayout'
import ErrorComponent from './components/errorComponet/errorComponent'
 const AppRoutes = () => {
    return (
       
       
        <Routes>
           <Route path="/"  element={(
            <AuthLayout authentication={true}>
              <HomePage />
            </AuthLayout>
           )} />
           <Route path="/login"  element={(
            <AuthLayout authentication={false}>
              <Login/>
            </AuthLayout>
           )} />
             <Route path="/caseName"  element={(
              //only who is logged in can see this page
              <AuthLayout authentication={true}>
                <CaseName />
              </AuthLayout>
             )} />
          <Route path="/legalIssues" element={(
            <AuthLayout authentication={true}>
              <LegalIssues />
            </AuthLayout>
          )} />
           <Route path="/caseDetails/:id" element={(
            <AuthLayout authentication={true}>
              <CaseDetails />
            </AuthLayout>
          )} />
          <Route path="/orders" element={(
            <AuthLayout authentication={true}>
              <Orders />
            </AuthLayout>
          )} />
          <Route path="/workDays" element={(
            <AuthLayout authentication={true}>
              <WorkDays />
            </AuthLayout>
          )} />
          <Route path='/appointments' element={(
            <AuthLayout authentication={true}>
              <Appointments/>
            </AuthLayout>
          )}/>
           <Route path='/users' element={(
            <AuthLayout authentication={true}>
              <Users/>
            </AuthLayout>
          )}/>
             <Route path='/posts' element={(
            <AuthLayout authentication={true}>
              <Posts/>
            </AuthLayout>
          )}/>
           <Route path='/addPost' element={(
            <AuthLayout authentication={true}>
              <AddPost/>
            </AuthLayout>
          )}/>
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      
    )
}
export default AppRoutes;
