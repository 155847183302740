import {createSlice} from "@reduxjs/toolkit";


const authSlice = createSlice({
    name: "auth",
    initialState: {
        isAuthenticated:  localStorage.getItem('userToken') !== null, // Check if userToken exists
        userToken: localStorage.getItem('userToken') || null,
    },
    reducers: {
        login: (state, action) => {
            state.isAuthenticated = true;
            state.userToken = action.payload;

            localStorage.setItem('userToken', action.payload);
        },
        logout: (state) => {
            state.isAuthenticated = false;
            state.userToken = null;
            localStorage.clear()
        }
    }

   
})

 export const { login, logout } = authSlice.actions;
 export const selectAuthStatus = (state) => state.auth.isAuthenticated;
export const selectUserToken = (state) => state.auth.userToken;


 export default authSlice.reducer;