import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import Navbar from "../../components/navbar";
import Sidebar from "../../components/sidebar";
import Footer from "../../components/footer";
import { DataGrid } from "@mui/x-data-grid";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import MarkChatUnreadOutlinedIcon from "@mui/icons-material/MarkChatUnreadOutlined";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import ScheduleIcon from "@mui/icons-material/Schedule";
import { Row, Col, Container } from "react-bootstrap";
import rocket from "../../Assets/images/rocket.png";
import profileImage from "../../Assets/images/profile placholder image.jpg";
import {useSelector,useDispatch } from "react-redux";
import {logout,selectUserToken } from "../../store/authSlice";
import {selectUserData} from '../../store/userDataSlice'
import ErrorComponent from '../../components/errorComponet/errorComponent'
import axios from "axios";
import "./home.css";

const HomePage = () => {
  const [caseData, setCaseData] = useState([]);
  const [usersCount,setUsersCount]=useState(0);
  const [requestsCount,setRequestsCount]=useState(0);
  const [error, setError] = useState("");
  const token = useSelector(selectUserToken);
  const userName = useSelector(selectUserData);
  const dispatch = useDispatch()
  useEffect(() => {
    getCases();
    getUsersCounts()
    getRequests()

  }, []);
  const getUsersCounts=async ()=>{
    try{
      const res = await axios.get(
        process.env.REACT_APP_API_URL +"/users?filters[role][name][$ne]=admin&fields[0]=id",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setUsersCount(formatCount(res.data.length))
    }catch(error){
      if(error.response.status===401 ||error.response.status===403){
        dispatch(logout());
        window.location.href = '/login';

    }else{
      setError("Error Occured in getting users count: " + error);

    }
    }
  }
  const getRequests=async ()=>{
    try{
      const res = await axios.get(
        process.env.REACT_APP_API_URL +"/appointment-requests?fields[0]=id",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setRequestsCount(formatCount(res.data.data.length))
    }catch(error){
      if(error.response.status===401 ||error.response.status===403){
          dispatch(logout());
          window.location.href = '/login';
 
      }else{
        setError("Error Occured in getting requests count: " + error);

      }
    }
  }
  const formatCount=(count) =>{
    if (count < 1000) {
        return count.toString();
    } else if (count < 1000000) {
        return (count / 1000) + 'k';
    } else if (count < 1000000000) {
        return (count / 1000000) + 'M';
    } else {
        return (count / 1000000000) + 'B';
    }
}
  const customizeDataLevels = (data) => {
    const formattedData = data.map((item) => {
      // condtion to handle user image if user not have image
      let userImage = item.attributes.user.data.attributes.image.data;
      if (userImage === null) {
        userImage = profileImage;
      } else {
        userImage =
          process.env.REACT_APP_URL + item.attributes.user.data.attributes.image.data.attributes.url;
      }
      return {
        id: item.id,
        caseTitle: item.attributes.title,
        description: item.attributes.description,
        caseDate: item.attributes.nextSessionDate.substring(0, 10),
        caseType: item.attributes.case_type.data.attributes.name,
        caseTypeID: item.attributes.case_type.data.id,
        caseState: item.attributes.case_status,
        userName: item.attributes.user.data.attributes.username,
        userEmail: item.attributes.user.data.attributes.email,
        user: item.attributes.user,
        userImage: userImage,
      };
    });

    return formattedData;
  };

  const getCases = async () => {
    try {
      const res = await axios.get(
        process.env.REACT_APP_API_URL +
          "/cases?populate[0]=case_type&populate[1]=user.image&populate[3]=case_status",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setCaseData(customizeDataLevels(res.data.data));
    } catch (error) {
      if(error.response.status===401 ||error.response.status===403){
        dispatch(logout());
        window.location.href = '/login';

    }else{
      setError("Error Occured in getting cases data: " + error);

    }
    }
  };
  const columns = [
    {
      field: "user",
      headerName: "بينات العميل",
      width: 290,
      renderCell: (params) => {
        return (
          <>
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <Avatar alt="Remy Sharp" src={params.row.userImage} />
              </ListItemAvatar>
              <ListItemText
                sx={{
                  textAlign: "start",
                }}
                primary={
                  <Typography
                    sx={{
                      fontSize: "15px",
                      fontWeight: "700",
                      fontFamily: "'Noto Sans Arabic', sans-serif",
                      color: "rgba(63, 63, 63, 0.8)",
                    }}
                    component="span"
                  >
                    {params.row.user.data.attributes.username}
                  </Typography>
                }
                secondary={
                  <React.Fragment>
                    <Typography
                      sx={{
                        color: "rgba(150, 149, 147, 0.8)",
                        fontSize: "11px",
                      }}
                      component="div"
                      variant="body2"
                    >
                      {params.row.user.data.attributes.email}
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItem>
          </>
        );
      },
    },
    {
      field: "caseTitle",
      headerName: "اسم القضيه",
      width: 150,
    },
    {
      field: "description",
      headerName: "وصف القضيه",
      width: 170,
    },
    {
      field: "caseType",
      headerName: " نوع القضيه  ",
      width: 170,
      renderCell: (params) => {
        return (
          <>
            <nav className="case-type">{params.row.caseType}</nav>
          </>
        );
      },
    },
    {
      field: "caseState",
      headerName: " حاله القضيه  ",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <nav
              className="case-status"
              style={{
                backgroundColor:
                  params.row.caseState.data.attributes.caseBoxColor,
                color: params.row.caseState.data.attributes.caseColor,
              }}
            >
              {params.row.caseState.data.attributes.name}
            </nav>
          </>
        );
      },
    },
    {
      field: "caseDate",
      headerName: "تاريخ البدء",
      width: 140,
    },
  ];
if(error){
  return <ErrorComponent error={error}/>
}
  return (
    // <ErrorComponent error={"error occured in the page content 401 error found"}/>
    <Box sx={{ width: 1 }}>
      <Box display="grid" gridTemplateColumns="repeat(11, 1fr)">
        <Box gridColumn="span 9"  className="mx-3 mt-3">
          <Navbar />
      
          <Box gridColumn="span 9" className="mt-3">
            <div dir="rtf" className="text-end pe-2">
              <nav
                className="d-inline-block mt-1 me-2 fw-normal fs-5"
                style={{ color: "rgba(36, 36, 36, 0.85)" }}
              >
                الاحصائيات
              </nav>
              <MarkChatUnreadOutlinedIcon
                sx={{ marginBottom: "0.4%", color: "rgba(36, 36, 36, 0.85)" }}
              />
            </div>

            <Container dir="rtl" className="px-4 m-0 mt-4 bg-white">
              <Row className="main-content">
                <Col sm={12} md={12} lg={5} >
                  <div>
                    <Row className="welcome-section p-4 rounded-3">
                      <Col sm={12} md={6} className="mb-4">
                        <div>
                          <div className="fw-bold fs-3">اهلا {userName} 🎉</div>
                          <div className="fw-medium fs-5 mt-1">
                            نتطلع لتقدم كبير في الالشهر
                          </div>
                          <a
                            href="/appointments"
                            className="d-inline-block mt-2 px-4 py-3 "
                          >
                            رؤيه المواعيد
                          </a>
                        </div>
                      </Col>
                      <Col sm={12} md={6} className="text-start">
                        <img src={rocket} alt="img" width={115} />
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col sm={12} md={12} lg={7} className="pe-md-5  h-100 mt-3 mt-md-0">
                  <div className="welcome-section p-4 rounded-3 h-100">
                    <div className="d-flex justify-content-between">
                      <div className="fs-4 fw-bold">إحصائيات</div>
                      <div
                        className="fs-6 fw-normal"
                        style={{ color: "rgba(150, 149, 147, 0.6)" }}
                      >
                        تم التحديث منذ شهر واحد
                      </div>
                    </div>
                    <Row className="mt-5">
                      <Col sm={12} md={4} className="d-flex mb-4 ">
                        <div className="icon-wrapper1 d-flex justify-content-center align-items-center rounded-5 p-4 ms-3">
                          <ShoppingCartOutlinedIcon />
                        </div>
                        <div>
                          <div className="fs-5 fw-bold">{requestsCount}</div>
                          <div className="fs-6 fw-normal">requests</div>
                        </div>
                      </Col>
                      <Col sm={12} md={4} className="d-flex mb-4">
                        <div className="icon-wrapper2 d-flex justify-content-center align-items-center rounded-5 p-4 ms-3">
                          <PeopleOutlineIcon />
                        </div>
                        <div>
                          <div className="fs-5 fw-bold">{usersCount}</div>
                          <div className="fs-6 fw-normal">new clients</div>
                        </div>
                      </Col>
                      <Col sm={12} md={4} className="d-flex mb-4">
                        <div className="icon-wrapper3 d-flex justify-content-center align-items-center rounded-5 p-4 ms-3">
                          <ScheduleIcon />
                        </div>
                        <div>
                          <div className="fs-5 fw-bold">230k</div>
                          <div className="fs-6 fw-normal">incomes</div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
            <Container className="bg-white">
              <div style={{ margin: "2% 0% 2.25% 0%" }} dir="rtl">
                <DataGrid
                  rows={caseData}
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 6,
                      },
                    },
                  }}
                  pageSizeOptions={[6]}
                  checkboxSelection
                  disableRowSelectionOnClick
                  className="fs-6 fw-medium"
                />
              </div>
            </Container>
          </Box>
        </Box>
        <Box gridColumn="span 2">
          <Sidebar />
        </Box>
        <Box gridColumn="span 11">
          <Footer />
        </Box>
      </Box>
    </Box>
  );
};

export default HomePage;
