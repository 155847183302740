import React, { useEffect, useState } from "react";
import {Grid,Tab,Tabs,NativeSelect,FormControl} from "@mui/material";
import {Button,Col,Row,Toast,ToastContainer,Alert } from "react-bootstrap";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import "./workDays.css";
import {useSelector } from "react-redux";
import {selectUserToken} from "../../store/authSlice";
import axios from "axios";


const DaysComponent = (props) => {
  const { keyIndex, dayname } = props;

  const date = new Date(dayname);

  // Get the day name in Arabic
  const options = { weekday: "long" }; // 'long' gives the full day name
  const day = date.toLocaleDateString("ar-EG", options);
  // Get the day in m/d/yyyy format
  const dayDate = date.toLocaleDateString(undefined, {
    day: "numeric",
    month: "numeric",
    year:'numeric',
  
  });
// Get the day in yyyy/mm/dd format
  const year2 = date.getFullYear();
const month2 = ('0' + (date.getMonth() + 1)).slice(-2);
const day2 = ('0' + date.getDate()).slice(-2); 
const dayDate2 = `${year2}-${month2}-${day2}`;


//-------inputs states---------
const [dayOldData,setDayOldData]=useState([])
const [isOff,setIsOff]=useState(false);
const [isOfficialVacation,setIsOfficialVacation]=useState(false)
const [error,setError]=useState('')
const [firstTimeMIN,setFirstTimeMIN] = useState("00")
const [firstTimeHR,setFirstTimeHR] = useState("00")
const [firstTimeState,setFirstTimeState] = useState("AM")
const [secondTimeMIN,setSecondTimeMIN] = useState("00")
const [secondTimeHR,setSecondTimeHR] = useState("00")
const [secondTimeState,setSecondTimeState] = useState("PM")
const [showToast, setShowToast] = useState(false);
const [toastValue, setToastValue] = useState("");
const [toastColor, setToastColor] = useState("");
const [toastIcon, setToastIcon] = useState(true);
const [showAlert, setShowAlert] = useState(false);


const token = useSelector(selectUserToken);

  useEffect(() => {
 
    getDay()
    
    }, []);



const handleSelectFTMinChange = (event) => {
  
  setFirstTimeMIN(event.target.value);
};
const handleSelectFTHRChange = (event) => {
 
  setFirstTimeHR(event.target.value);
};
const handleSelectFTStateChange = (event) => {
 
  setFirstTimeState(event.target.value);
};
const handleSelectSTMinChange = (event) => {
  
  setSecondTimeMIN(event.target.value);
};
const handleSelectSTHRChange = (event) => {
 
  setSecondTimeHR(event.target.value);
};
const handleSelectSTStateChange = (event) => {
 
  setSecondTimeState(event.target.value);
};
// handle time to be in strapi needed format
const handle24HRFormat=(hour,minute,status)=>{
  console.log(hour,minute,status)
  if (status === 'PM' && hour !== 12) {
  hour = parseInt(hour, 10);
    hour += 12;
  } else if (status === 'AM' && hour === 12) {
    hour = "00";
  }
  return `${hour}:${minute}:00`
}

const handleStrapiGetTime=(timeString)=>{
  const [hours, minutes, seconds] = timeString.split(":");
  const date = new Date();
  date.setHours(parseInt(hours, 10));
  date.setMinutes(parseInt(minutes, 10));
  date.setSeconds(parseInt(seconds, 10));
  
  let formattedHours = date.getHours();
  if (formattedHours > 12) {
      formattedHours -= 12;
  }
  formattedHours = formattedHours.toString().padStart(2, '0');
  const formattedMinutes = date.getMinutes().toString().padStart(2, '0');
  const ampm = date.getHours() >= 12 ? 'PM' : 'AM';
  return [formattedHours, formattedMinutes, ampm];
}

  //-------handle inputs disability-----------
  const [isDisabled1, setIsDisabled1] = useState(false);
  const [value, setValue] = useState("one");

  const handleChange1 = (event, newValue) => {
    setValue(newValue);
    if (newValue === "one") {
      setIsDisabled1(false);
      setIsOfficialVacation(false);
      setIsOff(false)
    } else if (newValue === "two") {
      setIsDisabled1(true);
      setIsOff(true);
      setIsOfficialVacation(false);
    }else if(newValue === "three"){
      setIsDisabled1(true)
      setIsOff(false)
      setIsOfficialVacation(true)
    }
  };

const updateDay=async ()=>{
  try{

       setShowAlert(false)
       const res= await axios.put(

          `${process.env.REACT_APP_API_URL}/work-days/${dayOldData[0].id}`,
          {
            data: {
              startTime:handle24HRFormat(firstTimeHR,firstTimeMIN,firstTimeState),
              endTime:handle24HRFormat(secondTimeHR,secondTimeMIN,secondTimeState),
              isOff:isOff,
              isOfficialVacation:isOfficialVacation
            },
           
          },
          {
            headers:{
              Authorization:`Bearer ${token}`
            }
          }
        )
      console.log(res)
            setShowToast(true);
            setToastValue("تم تعديل المواعيد");
            setToastColor("success");
            setToastIcon(true);
           
    
 
  }catch(error){
    setShowToast(true);
    setToastValue("لم يتم تعديل المواعيد ");
    setToastColor("danger");
    setToastIcon(false);
  }
}
const getDay =async()=>{
  try{
    const res= await axios.get(
      `${process.env.REACT_APP_API_URL}/work-days?filters[date][$eq]=${dayDate2}`,
      {
        headers:{
          Authorization:`Bearer ${token}`
        },
      }
    );
    
    const result=res.data.data;
    setDayOldData(res.data.data)
  if(result.length === 1){
    const [formattedHours, formattedMinutes, ampm] = handleStrapiGetTime(result[0].attributes.endTime);
    setFirstTimeHR(formattedHours)
    setFirstTimeMIN(formattedMinutes)
   setFirstTimeState(ampm)
    const [formattedHours2, formattedMinutes2, ampm2] = handleStrapiGetTime(result[0].attributes.startTime);
    setSecondTimeHR(formattedHours2)
    setSecondTimeMIN(formattedMinutes2)
    setSecondTimeState(ampm2)
    setIsOff(result[0].attributes.isOff)
    setIsOfficialVacation(result[0].attributes.isOfficialVacation)
     if(result[0].attributes.isOff === true){
      handleChange1(undefined, "two");
    }
    else if(result[0].attributes.isOfficialVacation === true){
      handleChange1(undefined, "three");
        }
  }
  }catch(error){
   setError("Error occured in getting day data: "+error)
  }
}

  const createWorkDay=async()=>{
    try {
     await axios.post(
        `${process.env.REACT_APP_API_URL}/work-days`,
        {
          data:{
            date:dayDate2,
            startTime:handle24HRFormat(firstTimeHR,firstTimeMIN,firstTimeState),
            endTime:handle24HRFormat(secondTimeHR,secondTimeMIN,secondTimeState),
            isOff:isOff,
            isOfficialVacation:isOfficialVacation

          }
         
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          },
        }
      );
     
        setShowToast(true);
        setToastValue("تم اضافة المواعيد");
        setToastColor("success");
        setToastIcon(true);
    } catch (error) {
      setShowToast(true);
      setToastValue("يوجد مشكله لم يتف اضافة المواعيد");
      setToastColor("danger");
      setToastIcon(false);
    }
  }

  if (error) {
    return <div className="fs-6 fw-semibold text-danger">{error}</div>;
  }
 
  return (
    <Grid
      container
      gap={4}
      dir="rtl"
      className="w-100 my-4"
      key={keyIndex}
    >
      {/* ----------First Main Sub Box--------- */}
 {/* alert message */}
 <Alert show={showAlert} variant="danger"   className="position-absolute alert-handle">
              <Alert.Heading> انتبه !</Alert.Heading>
              <p>هل انت متاكد من تعديل هذا الموعد </p>
              <p>الرجاء التاكد من مواعيد الحجوزات بهذا اليوم <a href="/appointments" target="_blank">توجه الى صفحه المواعيد</a></p>
              <hr />
              <div className="d-flex justify-content-end">
                <Button
                  className="mx-2"
                  onClick={() => setShowAlert(false)}
                  variant="outline-secondary"
                >
                  الغاء
                </Button>
                <Button 
                 onClick={updateDay}
                 variant="outline-danger">
                  نعم
                </Button>
              </div>
            </Alert>

            {/* taost message */}
            <Row>
              <Col xs={12}>
                <ToastContainer
                  dir="rtl"
                  className="position-absolute"
                  style={{ top: "100px", left: "5px" }}
                >
                  <Toast
                    onClose={() => setShowToast(false)}
                    show={showToast}
                    delay={10000}
                    autohide
                    animation
                    bg={toastColor}
                  >
                    <Toast.Body className="text-white">
                      {toastIcon ? <CheckCircleIcon /> : <ErrorIcon />}
                      <span className="px-2 text-white fs-6">{toastValue}</span>
                    </Toast.Body>
                  </Toast>
                </ToastContainer>
              </Col>
            </Row>
      <Grid
        xs={12}
        md={6.5}
        item 
        className="day-container"
      >
        {/* ----------Inline Sub Boxs----------- */}
        <Grid container className="h-100">
          {/* ----------Inline Sub Box1----------- */}
          <Grid
            xs={12}
            md={2}
            item
            className="day-inline-container" 
          >
            <span className="fs-6 fw-medium">
            {day} 
            </span>
             <span className="fs-6 fw-medium">{dayDate} </span>
          </Grid>
          {/* ----------Inline Sub Box2----------- */}
          <Grid
            xs={12}
            md={12}
            lg={4.7}
            item
           className="start-time-box"
          >
            <FormControl className=" w-25 pb-1">
             
              <NativeSelect
                disabled={isDisabled1} 
                className="w-100 h-100"
                onChange={handleSelectFTMinChange}
              >   <option value="00">{firstTimeMIN}</option>
              <option value="00" selected={"00"===firstTimeMIN}>00</option>
                 <option value="15" selected={"15"===firstTimeMIN}>15</option>
                <option value="30" selected={"30"===firstTimeMIN}>30</option>
                <option value="45" selected={"45"===firstTimeMIN}>45</option>
              </NativeSelect>
            </FormControl>
            <span>
              :
            </span>
            <FormControl className=" w-25 pb-1">
           
              <NativeSelect
                disabled={isDisabled1}
                className="w-100 h-100"
                onChange={handleSelectFTHRChange}
              >
              <option value="00" selected={"00"===firstTimeHR}>00</option>
                <option value="01" selected={"01"===firstTimeHR}>01</option>
                <option value="02" selected={"02"===firstTimeHR}>02</option>
                <option value="03" selected={"03"===firstTimeHR}>03</option>
                <option value="04" selected={"04"===firstTimeHR}>04</option>
                <option value="05" selected={"05"===firstTimeHR}>05</option>
                <option value="06"selected={"06"===firstTimeHR}>06</option>
                <option value="07"selected={"07"===firstTimeHR}>07</option>
                <option value="08"selected={"08"===firstTimeHR}>08</option>
                <option value="09"selected={"09"===firstTimeHR}>09</option>
                <option value="10"selected={"10"===firstTimeHR}>10</option>
                <option value="11" selected={"11"===firstTimeHR}>11</option>
                <option value="12" selected={"12"===firstTimeHR}>12</option>
              </NativeSelect>
            </FormControl>

            <FormControl className="w-30 pb-1 me-3">
              <NativeSelect
                disabled={isDisabled1}
                className="w-100 h-100"
                onChange={handleSelectFTStateChange}
              >
                <option value='PM' selected={"PM"===firstTimeState} >مساء</option>
                <option value='AM' selected={"AM"===firstTimeState} >صباحا</option>
              </NativeSelect>
            </FormControl>
          </Grid>

          {/* ----------Inline Sub Box3----------- */}
          <Grid
            xs={12}
            md={12}
            lg={4.7}
            item
           className="start-time-box border-0"
          >
           <FormControl className=" w-25 pb-1">
              <NativeSelect
                disabled={isDisabled1}
                className="w-100 h-100"
                onChange={handleSelectSTMinChange}
              >
                 <option value="15" selected={"15"=== secondTimeMIN}>15</option>
                <option value="30" selected={"30"=== secondTimeMIN}>30</option>
                <option value="45" selected={"45"=== secondTimeMIN}>45</option>
              </NativeSelect>
            </FormControl>
            <span>
              :
            </span>
            <FormControl className=" w-25 pb-1">
              <NativeSelect
                disabled={isDisabled1}
                className="w-100 h-100"
                onChange={handleSelectSTHRChange}
              >
                <option value="00" selected={"00"===secondTimeHR}>00</option>
                <option value="01" selected={"01"===secondTimeHR}>01</option>
                <option value="02" selected={"02"===secondTimeHR}>02</option>
                <option value="03" selected={"03"===secondTimeHR}>03</option>
                <option value="04" selected={"04"===secondTimeHR}>04</option>
                <option value="05" selected={"05"===secondTimeHR}>05</option>
                <option value="06"selected={"06"===secondTimeHR}>06</option>
                <option value="07"selected={"07"===secondTimeHR}>07</option>
                <option value="08"selected={"08"===secondTimeHR}>08</option>
                <option value="09"selected={"09"===secondTimeHR}>09</option>
                <option value="10"selected={"10"===secondTimeHR}>10</option>
                <option value="11" selected={"11"===secondTimeHR}>11</option>
                <option value="12" selected={"12"===secondTimeHR}>12</option>
              </NativeSelect>
            </FormControl>

            <FormControl className=" w-30 pb-1 me-3">
              <NativeSelect
                disabled={isDisabled1}
                className="w-100 h-100"
                onChange={handleSelectSTStateChange}
              >
               <option value='PM' selected={"PM"===secondTimeState} >مساء</option>
                <option value='AM' selected={"AM"===secondTimeState} >صباحا</option>
              </NativeSelect>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>

      {/* ----------Second Main Sub Box--------- */}
      <Grid
        xs={12}
        md={12}
        lg={4}
        item
       className="tabs-container"
      >
        <Tabs
          value={value}
          onChange={handleChange1}
          textColor="inherit"
          indicatorColor="#a87c2d"
          aria-label="secondary tabs example"
          sx={{
            "& .MuiTabs-indicator": { backgroundColor: "#a87c2d" },
            paddingX: 0,
            height: "100%",
          }} 
        >
          <Tab
            value="one"
            label="  يوم عمل"
            sx={{
              backgroundColor:
                value === "one" ? "rgba(168, 124, 45, 0.9)" : "inherit",
              color: value === "one" ? "white" : "#a87c2d",
              fontSize: "24.46px",
              width: "30%",
            }}
          />
          <Tab
            value="two"
            label="اجازه"
            sx={{
              backgroundColor:
                value === "two" ? "rgba(168, 124, 45, 0.9)" : "inherit",
              color: value === "two" ? "white" : "#a87c2d",
              fontSize: "24.46px",
              width: "30%",
            }}
          />
          <Tab
            value="three"
            label="عطله رسميه"
            sx={{
              backgroundColor:
                value === "three" ? "rgba(168, 124, 45, 0.9)" : "inherit",
              color: value === "three" ? "white" : "#a87c2d",
              fontSize: "24.46px",
              width: "40%",
            }}
          />
        </Tabs>
      </Grid>
      <Grid
        xs={12}
        item
      >
        <button
          dir="ltr"
         className="create-day-btn"
               onClick={ () =>{
                if(dayOldData.length === 0){
                  createWorkDay()
                }else{setShowAlert(true)}}}>
          حفظ
        </button>
      </Grid>
    
    </Grid>
  );
};
export default DaysComponent;
