import * as React from "react";
import {useEffect,useState} from 'react'
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import Tooltip from "@mui/material/Tooltip";
import {useDispatch,useSelector} from "react-redux"
import {logout,selectUserToken} from "../store/authSlice.js"
import {userDataInfo} from "../store/userDataSlice.js"
import {useNavigate} from "react-router-dom";
import axios from "axios";
import "./layoutStyle.css"

export default function Navbar() {
  const [data,setData]=useState([])
  const [error,setError]=useState("");
  const token = useSelector(selectUserToken);


 useEffect(()=>{
  handleAdminData()
},[])

 // handle log out
 const navigate = useNavigate()
 const dispatch = useDispatch()

 const handleAdminData =async ()=>{
  try {
      const res= await axios.get(
        process.env.REACT_APP_API_URL +
          "/users/me?populate[0]=image",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setData(res.data)
      
      dispatch(userDataInfo(res.data.username));
   
    } catch (error) {
      
      setError(error);
    }
}

 const handleLogout=async ()=>{
  try{
  dispatch(logout());
  navigate("/login")
  }catch(error){
  setError("Error occured in logout: ",error)
  }

 }
if(error){
  return <div className="fw-semibold fs-6 text-danger">{error}</div>
}
  return (
    <Box sx={{ flexGrow: 1 }} >
      <AppBar
        position="static"
        sx={{
          backgroundColor: "white",
          borderRadius: "10px",
          height: "80px",
          padding: "10px 0px",
          boxShadow: "0px 2px 4px 0px rgba(165, 163, 174, 0.3)",
        }}
      >
        <Toolbar >
          <div className="row w-100 h-100 p-0" >
          <div
          className="col-3 ps-4"
           
          >
            <Tooltip title="صوره">
              <IconButton
                sx={{ p: 0 }}
              >
                <Avatar
                  alt="Remy Sharp"
                  src={data.length !==0? process.env.REACT_APP_URL+data.image.url : "1.png"}
                  sx={{ width: 56, height: 56 }}
                />
              </IconButton>
            </Tooltip>
         
          </div>
        <div  className="col-9 d-flex justify-content-end align-items-center pe-0">      
            <div className="me-3 text-dark-emphasis fs-md-4 fs-sm-5 fs-6 text-end"> {data.username} : مرحبا</div>
            <Tooltip title="خروخ">
              <IconButton
          
                onClick={handleLogout}
                sx={{ p: 0 ,backgroundColor:'#DBDADE',width: 50, height: 50 }}
              >
               <ExitToAppIcon  sx={{ fontSize:'20' }}/>
              </IconButton>
            </Tooltip>
          </div>
          </div>
       

        </Toolbar>
      </AppBar>

    </Box>
  );
}
