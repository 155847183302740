import Box from "@mui/material/Box";
import Navbar from "../../components/navbar";
import Sidebar from "../../components/sidebar";
import Footer from "../../components/footer";
import React, { useEffect, useState } from "react";
import EditCalendarOutlinedIcon from "@mui/icons-material/EditCalendarOutlined";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DaysComponent from "./daysComponent";
import "./workDays.css";

const WorkDays = () => {
  //---------handle date input feilds--------------
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [dateDifference, setDateDifference] = useState(7);
  const [datesArray, setDatesArray] = useState([]);

  const handleEndDateChange = (e) => {
    const selectedEndDate = new Date(e.target.value);
    setEndDate(selectedEndDate);

    // Calculate the difference in days between the two dates
    const startDateTime = startDate.getTime();
    const endDateTime = selectedEndDate.getTime();
    const differenceInDays =
      Math.ceil((endDateTime - startDateTime) / (1000 * 3600 * 24)) + 2;
    setDateDifference(differenceInDays);
    // Generate an array of dates for each day in the date range
    const datesArray = [];
    for (let i = 0; i < differenceInDays; i++) {
      const currentDate = new Date(startDateTime + i * (1000 * 3600 * 24));
      datesArray.push(currentDate);
    }
    // Set the state to store the array of dates
    setDatesArray(datesArray);
    // console.log(datesArray);
  };
  useEffect(() => {
    // Calculate the date after 7 days
    const newEndDate = new Date(startDate);
    newEndDate.setDate(startDate.getDate() + 5);
    setEndDate(newEndDate);
    const startDateTime = startDate.getTime();
    // const endDateTime = endDate.getTime();

    const datesArray = [];
    for (let i = 0; i < dateDifference; i++) {
      const currentDate = new Date(startDateTime + i * (1000 * 3600 * 24));
      datesArray.push(currentDate);
    }
    // Set the state to store the array of dates
    setDatesArray(datesArray);
    // console.log(datesArray);
  }, [startDate]);




  return (
    <div>
    <Box sx={{backgroundColor: "rgba(248, 250, 252, 0.7)"}} >
      <Box
        display="grid"
        gridTemplateColumns="repeat(11, 1fr)"     
      >
        <Box
          gridColumn="span 9"
          className="mx-3 mt-3" 
                 >
          <Navbar />
          <Box gridColumn="span 9" >
            <div
              className="text-end my-4"
              dir="rtf"
             
            >
              <span className="side-text p-0 m-0 me-2">الانشاء </span>
              <KeyboardArrowLeftIcon sx={{ color: "rgba(36, 36, 36, 0.80)" }} />

              <span className="main-text p-0 m-0"> المواعيد (عمل - عطلة )</span>

              <EditCalendarOutlinedIcon
                sx={{ color: "rgba(36, 36, 36, 0.80)" }}
              />
            </div>

            <div dir="rtl">
              <input
                disabled
                type="text"
                className="input-style"
                value={startDate.toLocaleDateString(undefined, {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                })} // Format to 'YYYY-MM-DD'
                onChange={(e) => setStartDate(new Date(e.target.value))}
              />

              <ArrowBackIcon className="mt-1 mx-3"/>
              <input
                type="date"
                className="input-style"
                value={endDate.toISOString().split("T")[0]} // Format to 'YYYY-MM-DD'
                onChange={handleEndDateChange}
              />
            </div>

            {dateDifference > 0 && (
              <Box>
                {datesArray.map((day, index) => (
                  <div key={index}>
                    <DaysComponent keyIndex={index} dayname={day} />
                  </div>
                ))}
              </Box>
            )}
          </Box>
        </Box>
        <Box gridColumn="span 2">
          <Sidebar />
        </Box>
        <Box gridColumn="span 11">
          <Footer />
        </Box>
      </Box>
    </Box>
    </div>
  );
};

export default WorkDays;
