import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useSelector,useDispatch  } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {login,logout,selectAuthStatus} from '../store/authSlice'

 const AuthLayout = ({children, authentication = true}) => {
    const authStatus=useSelector(selectAuthStatus)
    const navigate=useNavigate()
    const [loader,setLoader]=useState(true)

    useEffect(()=> {
        //this means you are required to be logged in to see the page and you are not logged in
        if(authentication && authStatus !== authentication){
            navigate("/login")
           

          //this means you are not required to be logged in to see the page and you are logged in   
        } else if (!authentication && authStatus !== authentication){
            navigate("/")
        }
        setLoader(false)

    },[authStatus,authentication,navigate])



    return loader? <div>Loading...</div>: <>{children}</>
}

export default AuthLayout
// this component help in handeling user authorization to see dashboard pages
