import React, { useState, useEffect ,useRef} from "react";
import { Box} from "@mui/material";
import Navbar from "../../components/navbar";
import Sidebar from "../../components/sidebar";
import Footer from "../../components/footer";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import { Form ,Toast,ToastContainer,Row,Col} from "react-bootstrap";
import $ from 'jquery'; 
import 'dropify/dist/css/dropify.min.css';
import 'dropify/dist/js/dropify.min.js';
import "./posts.css";
import {useSelector } from "react-redux";
import {selectUserToken} from "../../store/authSlice";
import axios from "axios";

const AddPost = () => {
  const [postName, setPostName] = useState("");
  const [postDescription, setPostDescription] = useState("");
  const [postImage, setPostImage] = useState(null);
  const [caseTypesData, setCaseTypesData] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [toastValue, setToastValue] = useState("");
  const [toastColor, setToastColor] = useState("");
  const [toastIcon, setToastIcon] = useState(true);
  const [caseTypes, setCaseTypes] = useState([]);
  const [selectedCaseTypesID, setSelectedCaseTypesID] = useState([]);
  const inputRef = useRef(null);
  const token = useSelector(selectUserToken);
  useEffect(() => {
    $(inputRef.current).dropify({
      messages: {
        'default': 'Drag and drop a file here or click',
        'replace': 'Drag and drop or click to replace',
        'remove': 'Remove',
        'error': 'Oops, something wrong happened.'
      }
    });
    getCaseTypes();
  }, []);

  const createPost = async () => {
    try {
      const formData = new FormData();
      formData.append("files", postImage);
     await axios
        .post(`${process.env.REACT_APP_API_URL}/upload`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          const fileId = response.data[0].id;
          axios.post(
            `${process.env.REACT_APP_API_URL}/posts`,
            {
              data: {
                title: postName,
                content: postDescription,
                case_types: selectedCaseTypesID,
                image: fileId,
              },
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
        });
      

      setPostName("");
      setPostDescription("");
      setPostImage(null);
      setCaseTypes([]);
      setSelectedCaseTypesID([]);
      setShowToast(true);
      setToastValue("تم انشاء منشور جديده");
      setToastColor("success");
      setToastIcon(true);
    } catch (error) {
      setShowToast(true);
      setToastValue("يوجد مشكله لم تتم عمليه انشاء منشور جديده");
      setToastColor("danger");
      setToastIcon(false);
    }
  };
  const getCaseTypes = async () => {
    try {
      const res = await axios.get(
        process.env.REACT_APP_API_URL + "/case-types",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setCaseTypesData(res.data.data);
    } catch (error) {
      setShowToast(true);
      setToastValue("يوجد مشكله فى ايجاد انواع القضايا");
      setToastColor("danger");
      setToastIcon(false);
    }
  };
  const validationCheck = () => {
    if (
      postName === "" ||
      postDescription === "" ||
      postImage === "" ||
      caseTypes.length === 0
    ) {
      setShowToast(true);
      setToastValue(" برجاء ادخال جميع البيانات لتنفيذ العمليه");
      setToastColor("danger");
      setToastIcon(false);
    } else {
      createPost();
    }
  };

  //handle case types (choose,display,store)
  const handleRemove = (index) => {
    const updatedCaseTypes = [...caseTypes];
    updatedCaseTypes.splice(index, 1);
    setCaseTypes(updatedCaseTypes);
  };

  const handleSelectChange = (event) => {
    const selectedOption = event.target.value;
    setCaseTypes([...caseTypes, { caseType: selectedOption }]);
    const filteredData = caseTypesData.filter(
      (item) => item.attributes.name === event.target.value
    );
    setSelectedCaseTypesID([...selectedCaseTypesID, filteredData[0].id]);
  };

  return (
    <div>
      <Box sx={{ width: 1, backgroundColor: "rgba(248, 250, 252, 0.7)" }}>
        <Box
          display="grid"
          gridTemplateColumns="repeat(11, 1fr)"
          gap={2}
          sx={{
            width: "98.5%",
            marginLeft: "0.8%",
          }}
        >
          <Box gridColumn="span 9" sx={{ marginTop: "1%" }}>
            <Navbar />
            <Box
              gridColumn="span 9"
              sx={{
                marginTop: "2%",
              }}
            >
              {/* taost message */}
              <Row>
                <Col xs={12}>
                  <ToastContainer dir="rtl">
                    <Toast
                      onClose={() => setShowToast(false)}
                      show={showToast}
                      delay={10000}
                      autohide
                      animation
                      bg={toastColor}
                    >
                      <Toast.Body className="text-white">
                        {toastIcon ? <CheckCircleIcon /> : <ErrorIcon />}
                        <span className="px-2 text-white fs-6">
                          {toastValue}
                        </span>
                      </Toast.Body>
                    </Toast>
                  </ToastContainer>
                </Col>
              </Row>
              <div className="mt-0 text-end" dir="rtl">
                <NoteAddOutlinedIcon sx={{ color: "rgba(36, 36, 36, 0.80)" }} />

                <a href="/posts" className="side-text p-0 m-0 me-1">
                  المنشورات
                </a>
                <KeyboardArrowLeftIcon
                  className="mb-1"
                  sx={{ color: "rgba(36, 36, 36, 0.80)" }}
                />

                <span className="main-text m-0">اضافة منشور</span>
              </div>

              <div className="mt-5">
                <Row dir="rtl">
                  <Col sm={12} md={6} className="px-4 mb-4">
                    <Row className="p-4 border border-dark-subtle rounded-3">
                      <Col sm={12}>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>اسم المنشور</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="عنوان المنشور"
                            onChange={(e) => setPostName(e.target.value)}
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={12}>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlTextarea1"
                        >
                          <Form.Label>وصف المنشور</Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="وصف المنشور"
                            onChange={(e) => setPostDescription(e.target.value)}
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={12}>
                        <div className="caseType-select">
                          <select
                            className="w-100 "
                            onChange={handleSelectChange}
                            dir="rtl"
                          >
                            <option value="">اختر اسم الخدمه</option>
                            {caseTypesData.map((type, index) => (
                              <option key={index} value={type.attributes.name}>
                                {type.attributes.name}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="caseType-name-container">
                          {caseTypes.map((attachment, index) => (
                            <div key={index}>
                              <button onClick={() => handleRemove(index)}>
                                x
                              </button>
                              {attachment.caseType}
                            </div>
                          ))}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col sm={12} md={6}>
                    <div className="p-4 border border-dark-subtle rounded-3">
                    <Form.Label className="file-label mb-2 me-2">
                    صوره المنشور
                  </Form.Label >
                  <Form.Group
                    md="6"
                    controlId="validationCustom05"
                    className="file-style mb-3"
                  >
                    <input type="file" className='border-none' ref={inputRef}
                    onChange={(e) => setPostImage(e.target.files[0])}
                     />
                     
                  </Form.Group>
                   
                    </div>
                  </Col>
                  <Col sm={12} className="posts-btns mt-4 ">
                    <button
                      className="py-2 px-4 mb-3"
                      onClick={validationCheck}
                    >
                      حفظ المنشور
                    </button>
                    <button className="py-2 px-4">الغاء</button>
                  </Col>
                </Row>
              </div>
            </Box>
          </Box>
          <Box gridColumn="span 2">
            <Sidebar />
          </Box>
          <Box gridColumn="span 11">
            <Footer />
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default AddPost;
