import React, {useState, useEffect,useRef } from 'react';
import { Box, IconButton } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Navbar from "../../components/navbar";
import Sidebar from "../../components/sidebar";
import Footer from "../../components/footer";
import {Button,Toast,ToastContainer,Alert,Row,Col,Form} from "react-bootstrap";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import ErrorComponent from '../../components/errorComponet/errorComponent'
import $ from 'jquery'; 
import 'dropify/dist/css/dropify.min.css';
import 'dropify/dist/js/dropify.min.js';
import "./casesName.css";
import {useSelector } from "react-redux";
import { selectUserToken} from "../../store/authSlice";
import axios from "axios";

const CaseName = () => {
  const [data, setdata] = useState([]);
  const [categories, setCategories] = useState([]);
  const [caseName, setCaseName] = useState("");
  const [caseTypeImage, setCaseTypeImage] = useState(null);
  const [description, setDescription] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [error, setError] = useState("");
  const [creationError, setCreationError] = useState("");
  const [show, setShow] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastValue, setToastValue] = useState("");
  const [toastColor, setToastColor] = useState("");
  const [toastIcon, setToastIcon] = useState(true);
  const [deleteCaseID, setDeleteCaseID] = useState("");
    const inputRef = useRef(null);
  const token = useSelector(selectUserToken);


  useEffect(() => {
$(inputRef.current).dropify({
  messages: {
    'default': 'Drag and drop a file here or click',
    'replace': 'Drag and drop or click to replace',
    'remove': 'Remove',
    'error': 'Oops, something wrong happened.'
  }
});

    handleCaseNamesData();
    categoriesData();
  }, []);
 
  const deleteCase = async () => {
    try {
      setShowAlert(false);
       await axios.delete(
        `${process.env.REACT_APP_API_URL}/case-types/${deleteCaseID}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      
      window.location.reload();
      setShow(true);
      setToastValue("تم مسح القضيه");
      setToastColor("success");
      setToastIcon(true)
    } catch (error) {
      setShow(true);
      setToastValue("يوجد مشكله لم تتم عمليه مسح القضيه ");
      setToastColor("danger");
      setToastIcon(false)
      setError("Error occured in deleting the case type: "+error)
    }
  };
  const handleCreateCaseName = async () => {
    try {
      const formData = new FormData();
      formData.append("files", caseTypeImage);
        await axios
        .post(`${process.env.REACT_APP_API_URL}/upload`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          const fileId = response.data[0].id;
          axios.post(
            `${process.env.REACT_APP_API_URL}/case-types`,
            {
              data: {
                name: caseName,
                description: description,
                cases_category: selectedOption,
                image: fileId,
              },
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
        });

      setCaseName("");
      setDescription("");
      setCaseTypeImage(null);
      setCategories("");
      setShow(true);
      setToastValue("تم انشاء قضيه جديده");
      setToastColor("success");
      setToastIcon(true)

    } catch (error) {
      setShow(true);
      setToastValue("يوجد مشكله لم تتم عمليه انشاء قضيه جديده");
      setToastColor("danger");
      setToastIcon(false)
      setError("Error occured in creating the case type: "+error)

    }
  };
  const handleValidation = () => {
    if (
      caseName === "" ||
      selectedOption === "" ||
      description === "" ||
      caseTypeImage === null
    ) {
      setCreationError("برجاء ادخال جميع الخانات لتفعيل الطلب");
    } else {
      handleCreateCaseName();
      setCreationError("");
    }
  };

  const categoriesData = async () => {
    try {
      const res = await axios.get(
        process.env.REACT_APP_API_URL + "/cases-categories",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setCategories(res.data.data);
    } catch (error) {
      setError("Error occured in getting the cases categories: "+error)

    }
  };

  // handle to get cases type data
  const handleCaseNamesData = async () => {
    try {
      const res = await axios.get(
        process.env.REACT_APP_API_URL + "/case-types?populate=*",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      customizeDataLevels(res.data.data);
    } catch (error) {
      setError("Error occured in getting the old cases types: "+error)

    }
  };

  // this function used to handle the hierarchy of data come from strapi
  const customizeDataLevels = (data) => {
    const formattedData = data.map((item) => {
      return {
        id: item.id,
        name: item.attributes.name,
        description: item.attributes.description,
        caseCategoryTitle: item.attributes.cases_category.data.attributes.title,
      };
    });
    setdata(formattedData);
  };
  const handleDelete = async (id) => {
    setDeleteCaseID(id);
    setShowAlert(true);
  };

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleDescription = (event) => {
    setDescription(event.target.value);
  };
  const handlecaseName = (event) => {
    setCaseName(event.target.value);
  };
  const columns = [
    { field: "id", headerName: "رقم تعريفى", width: 80 },
    {
      field: "caseCategoryTitle",
      headerName: "اسم الفئة",
      width: 120,
      editable: true,
    },
    {
      field: "name",
      headerName: "اسم الخدمة",
      width: 120,
      editable: true,
    },
    {
      field: "description",
      headerName: "وصف الخدمة",
      width: 240,
      editable: true,
    },
    {
      field: "delete",
      headerName: "Delete",
      width: 100,
      renderCell: (params) => (
        <>
          <IconButton
            onClick={() => handleDelete(params.row.id)}
            className="p-0 m-0"
          >
            <img src="delete.png" alt="Delete Icon" />
          </IconButton>
        </>
      ),
    },
  ];
if (error){
  return  <ErrorComponent error={error}/>
}
  return (
    <Box sx={{ width: 1, backgroundColor: "rgba(248, 250, 252, 0.7)" }}>
      <Box
        display="grid"
        gridTemplateColumns="repeat(11, 1fr)"
      >
        <Box
          gridColumn="span 9"
          className="mx-3 mt-3"        >
          <Navbar />

          <Box gridColumn="span 9" sx={{ marginTop: "2%" }}>
            {/* alert message */}
            <Alert show={showAlert} variant="danger" className="w-25" dir="rtl">
              <Alert.Heading> انتبه !</Alert.Heading>
              <p>هل انت متاكد من مسح هذه القضيه</p>
              <hr />
              <div className="d-flex justify-content-end">
                <Button
                  className="mx-2"
                  onClick={() => setShowAlert(false)}
                  variant="outline-secondary"
                >
                  الغاء
                </Button>
                <Button onClick={deleteCase} variant="outline-danger">
                  نعم
                </Button>
              </div>
            </Alert>

          {/* taost message */}
          <Row>
              <Col xs={12}>
                <ToastContainer
                  dir="rtl"
                  className="position-absolute"
                  style={{ top: "100px", left: "5px" }}
                >
                  <Toast
                    onClose={() => setShowToast(false)}
                    show={showToast}
                    delay={10000}
                    autohide
                    animation
                    bg={toastColor}
                  >
                    <Toast.Body className="text-white">
                      {toastIcon ? <CheckCircleIcon /> : <ErrorIcon />}
                      <span className="px-2 text-white fs-6">{toastValue}</span>
                    </Toast.Body>
                  </Toast>
                </ToastContainer>
              </Col>
            </Row>

            <div className="mt-0 text-end" dir="rtf">
              <span className="side-text">الانشاء</span>
              <KeyboardArrowLeftIcon className="mb-1" sx={{ color: "rgba(36, 36, 36, 0.80)" }} />

              <span className="main-text m-0">اسماء القضايا (الخدمات)</span>

              <FileCopyOutlinedIcon sx={{ color: "rgba(36, 36, 36, 0.80)" }} />
            </div>
            <Box
              display="grid"
              gridTemplateColumns="repeat(12,1fr)"
              gap={4}
              dir="rtl"
            >
              <Box 
              gridColumn="span 5" 
              className="m-4 ms-0"
              sx={{
                '@media (max-width: 1000px)': {
                  gridColumn: 'span 12',
                },
              }}>
                <Form>
                  <select
                    value={selectedOption}
                    onChange={handleSelectChange}
                    className="select-style"
                 
                  >
                    <option value="">اختر الفئه</option>
                    {categories ? (
                      categories.map((category) => (
                        <option value={category.id}>
                          {category.attributes.title}
                        </option>
                      ))
                    ) : (
                      <option value="0">loading...</option>
                    )}
                  </select>
                  <Form.Group
                    className="mb-4"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Control
                      type="text"
                      placeholder="اسم القضية"
                      value={caseName}
                      onChange={handlecaseName}
                      className="text-right"
                      style={{ border: "2px solid #CAA75B", color: "#CAA75B" }}
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Control
                      as="textarea"
                      rows={3}
                      value={description}
                      onChange={handleDescription}
                      placeholder="وصف القضية"
                      className="text-right"
                      style={{ border: "2px solid #CAA75B", color: "#CAA75B" }}
                    />
                  </Form.Group>
                  <Form.Label className="file-label mb-2">
                    صوره القضيه
                  </Form.Label>
                  <Form.Group
                    md="6"
                    controlId="validationCustom05"
                    className="file-style mb-3"
                  >
                    <input type="file" className='border-none' ref={inputRef} onChange={(e) => setCaseTypeImage(e.target.files[0])}/>
                     
                  </Form.Group>
                            
                  <div dir="ltr">
                    <Button
                      style={{
                        backgroundColor: "#3D2318",
                        borderColor: "#3D2318",
                      }}
                      onClick={handleValidation}
                    >
                      اضافة
                    </Button>
                  </div>
                  <div>
                    {creationError ? (
                      <div className="fs-4 text-danger">{creationError}</div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                </Form>
              </Box>
              <Box
                gridColumn="span 7"
                className="m-3 ms-0 bg-body"
                sx={{
                  '@media (max-width: 1000px)': {
                    gridColumn: 'span 12',
                  },
                }}
              >
                <Box sx={{ width: "100%" }}>
                  <DataGrid
                  sx={{width:"100%"}}
                    rows={data}
                    columns={columns}
                    initialState={{
                      pagination: {
                        paginationModel: {
                          pageSize: 7,
                        },
                      },
                    }}
                    pageSizeOptions={[7]}
                    checkboxSelection
                    disableRowSelectionOnClick
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box gridColumn="span 2">
          <Sidebar />
        </Box>
        <Box gridColumn="span 11">
          <Footer />
        </Box>
      </Box>
    </Box>
  );
};

export default CaseName;
