import React from 'react'
import Box from '@mui/material/Box';
import DraftsOutlinedIcon from '@mui/icons-material/DraftsOutlined';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import BackHandOutlinedIcon from '@mui/icons-material/BackHandOutlined';
import EditCalendarOutlinedIcon from '@mui/icons-material/EditCalendarOutlined';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import { Link,useLocation  } from "react-router-dom";
import './layoutStyle.css'
import { Typography } from '@mui/material';
import logo4 from '../Assets/images/mutlaq.png'

const Sidebar = () => {

  const location = useLocation();


  const getListItemButtonStyle = (path, location) => {
    return {
      backgroundColor: location.pathname === path ? '#a87c2d' : 'inherit',
      color: location.pathname === path ? 'white' : 'black',
      borderRadius: '0.60rem'
    };
  };

  const getListItemIconStyle = (path, location) => {
    return { color: location.pathname === path ? 'white' : 'black',
     opacity: '0.7'
     }
  };

  const getListItemTextStyle = (path, location) => {
    return { textAlign: 'right',  fontFamily: "'Noto Sans Arabic'", fontSize:'15px',marginRight: '10px', color: location.pathname === path? 'white' : 'rgba(36, 36, 36, 0.8)'}
  };

  return (
    <>
 
 
     <Box
      sx={{
        width: '270px',
        height:'100%',
        backgroundColor: '#fff',
        overflowY: 'auto',
       padding:'10px',
       marginLeft: '6px',
        boxShadow: '-2px 0px 12px 0px rgba(148, 163, 184, 0.13)'
      }}
      role="presentation"
    >
        <ListSubheader sx={{margin:'0px',padding:'0px'}}>
          

        <img
          src={logo4} // replace with the path to your logo
          alt="Logo"
          style={{ height:'100%',width:'100%' }} // adjust width and margin as needed
          
        />
      </ListSubheader> 
        <List dir='rtl' >

      
        <Link to="/" style={{ textDecoration: 'none', color: 'black' }}>
      <ListItem disablePadding>
        <ListItemButton
           component="div"
           style={getListItemButtonStyle('/', location)}>
          <ListItemIcon sx={{ minWidth: 'unset', marginRight: 0 }}>
            <DraftsOutlinedIcon sx={getListItemIconStyle('/',location)} />
          </ListItemIcon>
          <ListItemText primary={
            <Typography  sx={getListItemTextStyle('/',location)}>
              الاحصائيات
            </Typography>
          }/>
        </ListItemButton>
      </ListItem>
        </Link>
       
        <h6 className='text-dark fw-bold mt-3 fs-6 opacity-50'>ادارة التطبيق</h6>


        <Link to="/appointments" style={{ textDecoration: 'none', color: 'black' }}>
      <ListItem disablePadding>
        <ListItemButton
           component="div"
           style={getListItemButtonStyle('/appointments', location)}>
          <ListItemIcon sx={{ minWidth: 'unset', marginRight: 0 }}>
            <EditCalendarOutlinedIcon sx={getListItemIconStyle('/appointments',location)} />
          </ListItemIcon>
          <ListItemText primary={
            <Typography  sx={getListItemTextStyle('/appointments',location)}>
              الموعيد
            </Typography>
          }/>
        </ListItemButton>
      </ListItem>
        </Link>

      <Link to="/orders" style={{ textDecoration: 'none', color: 'black' }}>
      <ListItem disablePadding>
        <ListItemButton
          component="div"
          style={getListItemButtonStyle('/orders', location)}
        >
        
          <ListItemIcon sx={{ minWidth: 'unset', marginRight: 0 }}>
            <BackHandOutlinedIcon sx={getListItemIconStyle('/orders',location)} />
          </ListItemIcon>
          <ListItemText primary={
            <Typography sx={getListItemTextStyle('/orders',location)}>
              الطلبات
            </Typography>
          }  />
        </ListItemButton>
      </ListItem>
      </Link> 

    <Link to="/legalIssues" style={{ textDecoration: 'none', color:'black' }}>
            <ListItem disablePadding>
            <ListItemButton
            component="div"
            style={getListItemButtonStyle('/legalIssues', location)}
            >
            <ListItemIcon sx={{ minWidth: 'unset', marginRight: 0 }} >
              <FileCopyOutlinedIcon sx={getListItemIconStyle('/legalIssues',location)}/>
                </ListItemIcon>
                <ListItemText primary={
                  <Typography sx={getListItemTextStyle('/legalIssues',location)}>
                    القضايا القانونية
                  </Typography>
                }   />
            </ListItemButton> 
            </ListItem>
            </Link> 


            <ListItem disablePadding>
            <ListItemButton>
            <ListItemIcon sx={{ minWidth: 'unset', marginRight: 0 }} >
              <RequestQuoteOutlinedIcon  sx={{ color: 'black' ,opacity:'0.7'}}/>
                </ListItemIcon>
                <ListItemText primary={
                  <Typography sx={{ textAlign: 'right',  fontFamily: "'Noto Sans Arabic'", fontSize:'15px',color:'rgba(36, 36, 36, 0.8)',marginRight:'10px'}}>
                    الفواتير
                  </Typography>
                } />
            </ListItemButton> 
            </ListItem>


            <Link to="/posts" style={{ textDecoration: 'none', color:'black' }}>
            <ListItem disablePadding>
            <ListItemButton
            component="div"
            style={getListItemButtonStyle('/posts', location)}
            >
            <ListItemIcon sx={{ minWidth: 'unset', marginRight: 0 }} >
              <NoteAddOutlinedIcon sx={getListItemIconStyle('/posts',location)}/>
                </ListItemIcon>
                <ListItemText primary={
                  <Typography sx={getListItemTextStyle('/posts',location)}>
                      المنشورات 
                  </Typography>
                }  />
            </ListItemButton> 
            </ListItem>
            </Link> 


            <Link to="/users" style={{ textDecoration: 'none', color:'black' }}>
            <ListItem disablePadding>
            <ListItemButton
            component="div"
            style={getListItemButtonStyle('/users', location)}
            >
            <ListItemIcon sx={{ minWidth: 'unset', marginRight: 0 }} >
              <PeopleOutlineIcon sx={getListItemIconStyle('/users',location)}/>
                </ListItemIcon>
                <ListItemText primary={
                  <Typography sx={getListItemTextStyle('/users',location)}>
                      المستخدمين 
                  </Typography>
                }  />
            </ListItemButton> 
            </ListItem>
            </Link> 

            <Divider sx={{ borderBottomWidth: '1px', backgroundColor: 'black' }} />
            <h6 className='mt-2 text-dark fw-bold fs-6 opacity-50'>انشاء</h6>

            <Link to="/caseName" style={{ textDecoration: 'none', color:'black' }}>
            <ListItem disablePadding>
            <ListItemButton
            component="div"
            style={getListItemButtonStyle('/caseName', location)}
            >
            <ListItemIcon sx={{ minWidth: 'unset', marginRight: 0 }} >
              <FileCopyOutlinedIcon sx={getListItemIconStyle('/caseName',location)}/>
                </ListItemIcon>
                <ListItemText primary={
                  <Typography sx={getListItemTextStyle('/caseName',location)}>
                    اسماء  القضايا (الخدمات)
                  </Typography>
                }  />
            </ListItemButton> 
            </ListItem>
            </Link> 

            <Link to="/workDays" style={{ textDecoration: 'none', color: 'black' }}>
            <ListItem disablePadding>
            <ListItemButton
            component="div"
            style={getListItemButtonStyle('/workDays', location)}
            >
            <ListItemIcon sx={{ minWidth: 'unset', marginRight: 0 }} >
              <EditCalendarOutlinedIcon  sx={getListItemIconStyle('/workDays',location)}/>
                </ListItemIcon>
                <ListItemText primary={
                  <Typography sx={getListItemTextStyle('/workDays',location)} >
                    المواعيد (عمل - عطلة )
                  </Typography>
                }  />
            </ListItemButton> 
            </ListItem>
            </Link>    
        </List>
      
      </Box>
    
      </> 
  );
};
export default Sidebar;





