import React from 'react'
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import "./errorComponent.css"
import foundError from "../../Assets/images/error.jpg"
const ErrorComponent = ({error}) => {
  return (
    <div className='d-flex flex-column justify-content-center align-items-center'style={{height:'100vh'}}>
      <img src={foundError} alt="" className='found-error-img' />
        <h1 className='text-dark fw-bolder fs-3 error-header text-capitalize'>Opps An Error Occured</h1> 
        <h4 className='mt-3  text-capitalize px-sm-5 px-2 error-text text-center'>{error} </h4> 
          <h5 className='mt-2 text-center px-sm-5 px-2 error-text text-capitalize '>Load the page again and try again your action or go to home page</h5>
          <a href='/login' className='error-btn px-4 py-2 mt-3 rounded-3 '>Home</a>
    </div>
  )
}

export default ErrorComponent