import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Navbar from "../../components/navbar";
import Sidebar from "../../components/sidebar";
import Footer from "../../components/footer";
import EditCalendarOutlinedIcon from "@mui/icons-material/EditCalendarOutlined";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import "./appointments.css";
import { Col, Container, Row, Toast, ToastContainer } from "react-bootstrap";
import { Form, Modal } from "react-bootstrap";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { logout, selectUserToken } from "../../store/authSlice";
import ErrorComponent from "../../components/errorComponet/errorComponent";

const Appointments = () => {
  const localizer = momentLocalizer(moment);
  const [data, setData] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [createModal, setCreateModal] = useState(false);
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [emailValid, setEmailValid] = useState("");
  const [meetingLinkValid, setMeetingLinkValid] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [meeting, setMeeting] = useState("");
  const [notes, setNotes] = useState("");
  const [appoitmentState, setAppoitmentState] = useState("");
  const [caseTypeData, setCaseTypeData] = useState([]);
  const [caseTypeID, setCaseTypeID] = useState("");
  const [validated, setValidated] = useState(false);
  const [error, setError] = useState("");
  const [creationError, setCreationError] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastValue, setToastValue] = useState("");
  const [toastColor, setToastColor] = useState("");
  const [toastIcon, setToastIcon] = useState(true);
  const token = useSelector(selectUserToken);
  const dispatch = useDispatch();
  useEffect(() => {
    getAppoitments();
    getcasesTypes();
  }, []);
  const handleUpdateState = async () => {
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/appointments/${selectedEvent.id}`,
        {
          data: {
            state: appoitmentState,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setShowToast(true);
      setToastValue("تم تعديل الموعد");
      setToastColor("success");
      setToastIcon(true);
    } catch (error) {
      setError("Error occured in update appoitment state: ", error);
    }
  };
  const getUserByEmail = async (email) => {
    try {
      const res = await axios.get(
        process.env.REACT_APP_API_URL + `/users?filters[email][$eq]=${email}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return res.data;
    } catch (error) {
      setError("Error occured in getting user: ", error);
    }
  };
  const customizeDataLevels = (data) => {
    const formattedData = data.map((item) => {
      return {
        id: item.id,
        title: item.attributes.state,
        start: new Date(
          item.attributes.date + "T" + item.attributes.startingTime
        ),
        data: item.attributes.date,
        end: new Date(item.attributes.date + "T" + item.attributes.endingTime),
        description: item.attributes.description,
        meetingLink: item.attributes.meetingLink,
        caseType: item.attributes.case_type,
        user: item.attributes.user,
      };
    });
    setData(formattedData);
    return formattedData;
  };
  const getAppoitments = async () => {
    try {
      const res = await axios.get(
        process.env.REACT_APP_API_URL +
          "/appointments?populate[0]=user&populate[1]=case_type",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      customizeDataLevels(res.data.data);
    } catch (error) {
      if (error.response.status === 401 || error.response.status === 403) {
        dispatch(logout());
        window.location.href = "/login";
      } else {
        setError("Error occured in getting appoitments: ", error);
      }
    }
  };
  const getcasesTypes = async () => {
    try {
      const res = await axios.get(
        process.env.REACT_APP_API_URL + "/case-types",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setCaseTypeData(res.data.data);
    } catch (error) {
      if (error.response.status === 401 || error.response.status === 403) {
        dispatch(logout());
        window.location.href = "/login";
      } else {
        setError("Error occured in getting cases types: ", error);
      }
    }
  };
  const createAppoitment = async () => {
    try {
      const userInfo = await getUserByEmail(email);
      if (userInfo.length === 0) {
        setCreationError("لا يوجد مستخدم بهذا الاسم");
      } else {
        await axios.post(
          `${process.env.REACT_APP_API_URL}/appointments`,
          {
            data: {
              meetingLink: meeting,
              startingTime: time + ":00",
              endingTime: addOneHour(time + ":00"),
              date: date,
              user: [userInfo[0].id],
              case_type: [caseTypeID],
              note: notes,
            },
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setShowToast(true);
        setToastValue("تم انشاء حجز جديد");
        setToastColor("success");
        setToastIcon(true);
        window.location.reload();
      }
    } catch (error) {
      setError("Error occured in creatig new appoitment: ", error);
    }
  };

  const eventStyleGetter = (event, start, end, isSelected) => {
    let backgroundColor;
    let color;
    let textAlign = "center";
    switch (event.title) {
      case "موعد ملغى":
        backgroundColor = "rgba(234, 84, 85, 0.16) !important";
        color = "rgba(234, 84, 85, 1)";
        break;
      case "موعد قيد الانتظار":
        backgroundColor = "rgba(255, 159, 67, 0.16) !important";
        color = "rgba(255, 159, 67, 1)";
        break;
      case "موعد ناجح":
        backgroundColor = "rgba(40, 199, 111, 0.16) ";
        color = "rgba(40, 199, 111, 1)";
        break;
      default:
        backgroundColor = "rgba(202, 167, 91, 0.24)";
        color = "rgba(168, 124, 45, 1)";
    }
    return {
      style: {
        backgroundColor,
        color,
        textAlign,
      },
    };
  };

  const addOneHour = (time) => {
    let [hours, minutes, seconds] = time.split(":");
    hours = parseInt(hours);
    minutes = parseInt(minutes);
    seconds = parseInt(seconds);

    // Handle the case when the hour is 23:00:00
    if (hours === 23) {
      hours = 0;
    } else {
      hours += 1;
    }

    // Format hours, minutes, and seconds back to strings
    hours = hours.toString().padStart(2, "0");
    minutes = minutes.toString().padStart(2, "0");
    seconds = seconds.toString().padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`;
  };
  const handleCreateSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false || creationError === "") {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
  };

  const handleMeetingChange = (e) => {
    const link = e.target.value;
    setMeeting(link);
    const regex =
      /(https?:\/\/www\.|https?:\/\/)?[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})(\.[a-zA-Z0-9]{2,})?/;
    setMeetingLinkValid(regex.test(link));
  };

  const handleEmailChange = (e) => {
    const email = e.target.value;
    setEmail(email);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setEmailValid(emailRegex.test(email));
  };
  const handleCreateModalOpen = (event) => {
    setCreateModal(true);
  };
  const handleCreateModalClose = () => {
    setCreateModal(false);
  };
  const handleSelectEvent = (event) => {
    setSelectedEvent(event);
  };
  const handleCloseModal = () => {
    setSelectedEvent(null);
  };

  const formatTime = (date) => {
    return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  };
  if (error) {
    return <ErrorComponent error={error} />;
  }
  return (
    <Box sx={{ width: 1, backgroundColor: "rgba(248, 250, 252, 0.7)" }}>
      <Box display="grid" gridTemplateColumns="repeat(11, 1fr)">
        <Box gridColumn="span 9" className="mx-3 mt-3">
          <Navbar />
          <Box gridColumn="span 9" sx={{ marginTop: "2%" }}>
            {/* taost message */}
            <Row>
              <Col xs={12}>
                <ToastContainer
                  dir="rtl"
                  className="position-absolute"
                  style={{ top: "100px", left: "5px" }}
                >
                  <Toast
                    onClose={() => setShowToast(false)}
                    show={showToast}
                    delay={10000}
                    autohide
                    animation
                    bg={toastColor}
                  >
                    <Toast.Body className="text-white">
                      {toastIcon ? <CheckCircleIcon /> : <ErrorIcon />}
                      <span className="px-2 text-white fs-6">{toastValue}</span>
                    </Toast.Body>
                  </Toast>
                </ToastContainer>
              </Col>
            </Row>
            <div
              dir="rtf"
              style={{ textAlign: "right", margin: "0% 0% 2% 0%" }}
            >
              <span
                className="me-1 fw-bold"
                style={{ color: "rgba(36, 36, 36, 0.80)" }}
              >
                الموعيد
              </span>
              <EditCalendarOutlinedIcon
                sx={{ color: "rgba(36, 36, 36, 0.80)" }}
              />
            </div>

            {/* ------------calender code-------------- */}

            <div
              className="bg-white mt-3 p-3 rounded-3"
              style={{ boxShadow: "0 0px 15px rgba(0, 0, 0, 0.10)" }}
            >
              <Container className="bg-white">
                <Row>
                  <Col sx={{}} dir="rtl">
                    <button
                      href="#"
                      className="main-btn w-25 p-2"
                      onClick={handleCreateModalOpen}
                    >
                      انشاء قضيه جديده +
                    </button>
                  </Col>
                </Row>
              </Container>

              <div style={{ height: "95vh", overflowX: "auto" }}>
                <Calendar
                  localizer={localizer}
                  events={data}
                  defaultView={"month"}
                  views={["month", "week", "day"]}
                  startAccessor="start"
                  onSelectEvent={handleSelectEvent}
                  eventPropGetter={eventStyleGetter}
                />
                {/* ------create appoitment modal-------- */}
                <Modal
                  className=""
                  show={createModal}
                  onHide={handleCreateModalClose}
                  dir="rtl"
                  centered
                >
                  <Form
                    noValidate
                    validated={validated}
                    onSubmit={handleCreateSubmit}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>اضافه حجز</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Container className="bg-white">
                        <Row>
                          <Col sm={12}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label className="me-1">
                                البريد الالكتروني
                              </Form.Label>
                              <Form.Control
                                type="email"
                                placeholder="ايميل صاحب القضية"
                                onChange={handleEmailChange}
                                isInvalid={!emailValid && email.length > 0}
                              />
                              {!emailValid && email.length > 0 && (
                                <Form.Control.Feedback type="invalid">
                                  الرجاء إدخال بريد إلكتروني صحيح
                                </Form.Control.Feedback>
                              )}
                            </Form.Group>
                          </Col>

                          <Col sm={12}>
                            <Form.Group
                              controlId="validationCustom06"
                              className="mb-3"
                            >
                              <Form.Label className="me-1">
                                اسم العميل
                              </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="اسم العميل"
                                required
                                onChange={(e) => setUserName(e.target.value)}
                              />
                              <Form.Control.Feedback type="invalid">
                                برجاء ادخال الاسم كامل
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col sm={12}>
                            <Form.Group
                              controlId="validationCustom06"
                              className="mb-3"
                            >
                              <Form.Label className="me-1">التاريخ</Form.Label>
                              <Form.Control
                                type="date"
                                placeholder=" تاريخ"
                                required
                                onChange={(e) => setDate(e.target.value)}
                              />
                              <Form.Control.Feedback type="invalid">
                                برجاء ادخال تاريخ الحجز
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>

                          <Col sm={12}>
                            <Form.Group
                              controlId="validationCustom06"
                              className="mb-3"
                            >
                              <Form.Label className="me-1">الوقت</Form.Label>
                              <Form.Control
                                type="time"
                                placeholder=" الوقت"
                                required
                                onChange={(e) => setTime(e.target.value)}
                              />
                              <Form.Control.Feedback type="invalid">
                                برجاء ادخال توقيت الحجز
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col sm={12}>
                            <Form.Label className="me-1">
                              نوع الاستشاره
                            </Form.Label>
                            <select
                              className="w-100 p-2 my-2 border border-dark-subtle rounded-1 text-light-emphasis appoitment-status d-block"
                              onChange={(e) => setCaseTypeID(e.target.value)}
                            >
                              <option value="" disabled selected>
                                اختر نوع القضيه
                              </option>
                              {caseTypeData ? (
                                caseTypeData.map((type) => (
                                  <option
                                    value={type.id}
                                    key={type.id}
                                    selected={type.id === caseTypeID}
                                  >
                                    {type.attributes.name}
                                  </option>
                                ))
                              ) : (
                                <option value="0">loading...</option>
                              )}
                            </select>
                          </Col>
                          <Col sm={12}>
                            <Form.Group
                              controlId="validationCustom06"
                              className="mb-3"
                            >
                              <Form.Label className="me-1">
                                رابط الموعد
                              </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=" رابط الموعد"
                                required
                                onChange={handleMeetingChange}
                                isInvalid={
                                  !meetingLinkValid && meeting.length > 0
                                }
                              />
                              {!meetingLinkValid && meeting.length > 0 && (
                                <Form.Control.Feedback type="invalid">
                                  برجاء ادخال رابط الحجز
                                </Form.Control.Feedback>
                              )}
                            </Form.Group>
                          </Col>
                          <Col sm={12}>
                            <Form.Group
                              controlId="validationCustom06"
                              className="mb-3"
                            >
                              <Form.Label className="me-1"> ملاحظات</Form.Label>
                              <Form.Control
                                type="textarea"
                                placeholder="ملاحظات"
                                required
                                onChange={(e) => setNotes(e.target.value)}
                              />
                            </Form.Group>
                          </Col>
                          {creationError && (
                            <div className="fs-5 fw-normal text-danger">
                              {creationError}
                            </div>
                          )}
                        </Row>
                      </Container>
                    </Modal.Body>
                    <Modal.Footer className="m-0 ">
                      <button
                        href="#"
                        type="submit"
                        className="main-btn"
                        onClick={createAppoitment}
                      >
                        حجز
                      </button>
                      <button
                        className="close-btn"
                        onClick={handleCreateModalClose}
                      >
                        عوده
                      </button>
                    </Modal.Footer>
                  </Form>
                </Modal>
                {/* -------appoitmnet details Modal------ */}
                <Modal
                  className=""
                  show={selectedEvent !== null}
                  onHide={handleCloseModal}
                  dir="rtl"
                  centered
                >
                  <Modal.Header closeButton>
                    {selectedEvent && (
                      <Modal.Title>
                        {selectedEvent.caseType.data.attributes.name}{" "}
                      </Modal.Title>
                    )}
                  </Modal.Header>
                  <Modal.Body>
                    {selectedEvent && (
                      <Container className="bg-white w-100">
                        <Row>
                          <Col sm={12} md={4}>
                            <div className="meeting-time">
                              {formatTime(selectedEvent.start)}
                            </div>
                          </Col>
                          <Col sm={12} md={8}>
                            <div className="main-contnet">
                              {selectedEvent.user.data.attributes.username}
                            </div>
                            <div className="mail-style" style={{}}>
                              {selectedEvent.user.data.attributes.email}
                            </div>

                            <select
                              className="w-75 p-1 mt-2  border border-dark-subtle rounded-1 text-light-emphasis appoitment-status"
                              onChange={(e) =>
                                setAppoitmentState(e.target.value)
                              }
                            >
                              <option disabled selected>
                                اختر حاله الحجز
                              </option>
                              <option value="موعد قيد الانتظار">
                                قيد الانتظار
                              </option>
                              <option value="موعد ناجح">ناجح</option>
                              <option value="موعد ملغى">ملغى</option>
                            </select>
                            <button
                              className="save-btnn me-2 rounded-1 p-1"
                              onClick={handleUpdateState}
                            >
                              تعديل
                            </button>
                          </Col>
                        </Row>
                      </Container>
                    )}
                  </Modal.Body>
                  {selectedEvent ? (
                    <Modal.Footer className="m-0 ">
                      <button
                        onClick={() =>
                          window.open(selectedEvent.meetingLink, "_blank")
                        }
                        className="main-btn p-2"
                      >
                        الدخول الي الموعد
                      </button>
                      <button
                        className="close-btnn p-2"
                        onClick={handleCloseModal}
                      >
                        عوده
                      </button>
                    </Modal.Footer>
                  ) : (
                    <Modal.Footer className="m-0 ">
                      <button
                        href="#"
                        className="main-btn p-2"
                        onClick={handleCloseModal}
                      >
                        الدخول الي الموعد
                      </button>
                      <button
                        className="close-btnn p-2"
                        onClick={handleCloseModal}
                      >
                        عوده
                      </button>
                    </Modal.Footer>
                  )}
                </Modal>
              </div>
            </div>
          </Box>
        </Box>
        <Box gridColumn="span 2">
          <Sidebar />
        </Box>
        <Box gridColumn="span 11">
          <Footer />
        </Box>
      </Box>
    </Box>
  );
};

export default Appointments;
