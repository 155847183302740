import React, { useState, useEffect } from "react";
import { Box, IconButton } from "@mui/material";
import Navbar from "../../components/navbar";
import Sidebar from "../../components/sidebar";
import Footer from "../../components/footer";
import BlockIcon from "@mui/icons-material/Block";
import { DataGrid } from "@mui/x-data-grid";
import { Form, Button, Modal } from "react-bootstrap";
import {Row,Col,Toast,ToastContainer,Alert} from "react-bootstrap";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import {Avatar,ListItem} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import ErrorIcon from "@mui/icons-material/Error";
import {useSelector } from "react-redux";
import {selectUserToken} from "../../store/authSlice";
import axios from "axios";
import "./posts.css";


const Posts = () => {
  const [data, setdata] = useState([]);
  const [allData, setAllData] = useState([]);
  const [postName, setPostName] = useState("");
  const [postDescription, setPostDescription] = useState("");
  const [caseTypesData, setCaseTypesData] = useState([]);
  const [editPostID, setEditPostID] = useState("");
  const [deletePostID, setDeletePostID] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [toastValue, setToastValue] = useState("");
  const [toastColor, setToastColor] = useState("");
  const [toastIcon, setToastIcon] = useState(true);
  const [showUpdateModal, setShowUpdateModal] = useState(false);

  useEffect(() => {
    getPosts();
    getCaseTypes();
  }, []);

  const token = useSelector(selectUserToken);

  const deletePost = async () => {
    try {
      setShowAlert(false);
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/posts/${deletePostID}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      setShowToast(true);
      setToastValue("يوجد مشكله لم تتم عمليه مسح القضيه ");
      setToastColor("danger");
      setToastIcon(ErrorIcon);
      
    }
  };
  const handleDelete = async (id) => {
    setDeletePostID(id);
    setShowAlert(true);
  };
  const handleBlock = async (id) => {
    try {
      const post = await axios.get(
        `${process.env.REACT_APP_API_URL}/posts/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      let postState = post.data.data.attributes.postStatus;
      if (postState === "notPublished") {
        postState = "published";
      } else {
        postState = "notPublished";
      }

      await axios.put(
        `${process.env.REACT_APP_API_URL}/posts/${id}`,
        {
          data: {
            postStatus: postState,
          },
        },

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      setShowToast(true);
      setToastValue(":يوجد مشكله فى تعديل حاله منشور رقم", id);
      setToastColor("danger");
      setToastIcon(false);
    }
    setShowToast(true);
    setToastValue("تم تعديل حاله المنشور");
    setToastColor("success");
    setToastIcon(true);
    setShowUpdateModal(false);
   
  };

  //handle update post
  const handleUpdateModalClose = () => setShowUpdateModal(false);
  const handleUpdateModalShow = (id) => {
    setShowUpdateModal(true);
    getPostByID(id);
  };
  const handleUpdatePost = async () => {
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/posts/${editPostID}`,
        {
          data: {
            title: postName,
            content: postDescription,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      setShowToast(true);
      setToastValue(":يوجد مشكله فى تعديل منشور رقم", editPostID);
      setToastColor("danger");
      setToastIcon(false);
    }
    setShowToast(true);
    setToastValue("تم تعديل المنشور");
    setToastColor("success");
    setToastIcon(true);
    setShowUpdateModal(false);
    window.location.reload();
  };

  const getPostByID = async (id) => {
    try {
      const res = await axios.get(
        process.env.REACT_APP_API_URL + `/posts/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setEditPostID(res.data.data.id);
      setPostName(res.data.data.attributes.title);
      setPostDescription(res.data.data.attributes.content);
    } catch (error) {
      setShowToast(true);
      setToastValue(":يوجد مشكله فى ايجاد منشور رقم", id);
      setToastColor("danger");
      setToastIcon(false);
    }
  };
  const customizeDataLevels = (data) => {
    const formattedData = data.map((item) => {
      return {
        id: item.id,
        title: item.attributes.title,
        image:
         process.env.REACT_APP_URL + item.attributes.image.data.attributes.url,
        content: item.attributes.content,
        caseTypes: item.attributes.case_types,
        postStatus: item.attributes.postStatus,
        publishedAt: item.attributes.publishedAt.substring(0, 10),
        caseTypeCount: item.attributes.case_types.data.length,
      };
    });
    return formattedData;
  };

  const getPosts = async () => {
    try {
      const res = await axios.get(
        process.env.REACT_APP_API_URL +
          "/posts?populate[0]=case_types&populate[1]=image",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      customizeDataLevels(res.data.data);
      setdata(customizeDataLevels(res.data.data));
      setAllData(customizeDataLevels(res.data.data));
    } catch (error) {
      setShowToast(true);
      setToastValue("يوجد مشكله فى ايجاد المنشورات");
      setToastColor("danger");
      setToastIcon(false);
    }
  };

  const getCaseTypes = async () => {
    try {
      const res = await axios.get(
        process.env.REACT_APP_API_URL + "/case-types",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setCaseTypesData(res.data.data);
    } catch (error) {
      console.log(error);
      setShowToast(true);
      setToastValue("يوجد مشكله فى ايجاد انواع القضايا");
      setToastColor("danger");
      setToastIcon(false);
    }
  };
  const DataFilteredByCaseType = (caseTypeName) => {
    if (caseTypeName === "الكل") {
      setdata(allData);
    } else {
      const filteredData = allData.filter(
        (item) =>
          item.caseTypes &&
          item.caseTypes.data.filter(
            (type) => type.attributes.name === caseTypeName
          ).length > 0
      );
      setdata(filteredData);
    }
  };
  const handleCaseTypeChange = (event) => {
    DataFilteredByCaseType(event.target.value);
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 10,
    },
    {
      field: "image",
      headerName: "صوره المنشور",
      width: 130,
      renderCell: (params) => {
        return (
          <>
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <Avatar alt="صوره المنشور" src={params.row.image} />
              </ListItemAvatar>
            </ListItem>
          </>
        );
      },
    },
    {
      field: "title",
      headerName: "اسم المنشور",
      width: 170,
      editable: true,
    },
    {
      field: "content",
      headerName: "وصف المنشور",
      width: 200,
      editable: true,
    },
    {
      field: "caseTypes",
      headerName: "انواع القضايا",
      width: 150,
      renderCell: (params) => {
        return (
          <div>
            {params.row.caseTypes.data.map((item, index) => (
              <div key={index} className="post-case-types p-2 my-1">
                {item.attributes.name}
              </div>
            ))}
          </div>
        );
      },
    },
    {
      field: "postStatus",
      headerName: " حاله المنشور  ",
      width: 120,
      renderCell: (params) => {
        if (params.row.postStatus === "published") {
          return (
            <>
              <nav className="post-unblock-state">فعال</nav>
            </>
          );
        } else {
          return (
            <>
              <nav className="post-block-state">غير فعال</nav>
            </>
          );
        }
      },
    },
    {
      field: "publishedAt",
      headerName: "تاريخ المنشور",
      width: 150,

      editable: true,
    },

    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <IconButton
              onClick={() => handleDelete(params.row.id)}
              sx={{ color: "#A87C2D" }}
            >
              {" "}
              <DeleteOutlineIcon />
            </IconButton>
            <IconButton
              onClick={() => handleUpdateModalShow(params.row.id)}
              sx={{ color: "#A87C2D" }}
            >
              {" "}
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={() => handleBlock(params.row.id)}
              sx={{ color: "#A87C2D" }}
            >
              {" "}
              <BlockIcon />
            </IconButton>
          </>
        );
      },
    },
  ];

  return (
    <div>
      <Box sx={{ width: 1, backgroundColor: "rgba(248, 250, 252, 0.7)" }}>
        <Box
          display="grid"
          gridTemplateColumns="repeat(11, 1fr)"
      
        >
          <Box gridColumn="span 9" className="mx-3 mt-3">
            <Navbar />
            <Box
              gridColumn="span 9"
              className="mt-4"
            >
               {/* alert message */}
               <Alert show={showAlert} variant="danger"   className="w-25"
                 >
              <Alert.Heading> انتبه !</Alert.Heading>
              <p>هل انت متاكد من مسح هذه القضية</p>
              <hr />
              <div className="d-flex justify-content-end">
                <Button
                  className="mx-2"
                  onClick={() => setShowAlert(false)}
                  variant="outline-secondary"
                >
                  الغاء
                </Button>
                <Button 
                onClick={deletePost}
                 variant="outline-danger">
                  نعم
                </Button>
              </div>
            </Alert>

            {/* taost message */}
            <Row>
              <Col xs={12}>
                <ToastContainer
                  dir="rtl"
                  className="position-absolute"
                  style={{ top: "100px", left: "5px" }}
                >
                  <Toast
                    onClose={() => setShowToast(false)}
                    show={showToast}
                    delay={10000}
                    autohide
                    animation
                    bg={toastColor}
                  >
                    <Toast.Body className="text-white">
                      {toastIcon ? <CheckCircleIcon /> : <ErrorIcon />}
                      <span className="px-2 text-white fs-6">{toastValue}</span>
                    </Toast.Body>
                  </Toast>
                </ToastContainer>
              </Col>
            </Row>

              <div className="mb-5" dir="rtl">
                <NoteAddOutlinedIcon sx={{ color: "rgba(36, 36, 36, 0.80)" }} />
                <span
                  className="me-2 fw-bold"
                  style={{
                    color: "rgba(36, 36, 36, 0.80)",
                  }}
                >
                  المنشورات
                </span>
              </div>
           
              {/* Main Content container */}
              <div dir="rtl">
                <div>
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={1}
                  >
                    <Box
                      sx={{
                        gridColumn: {
                          xs: "span 12",
                          sm: "span 6",
                        },
                      }}
                    >
                      <Button
                        className="w-50 h-75 mb-4 fs-5 d-flex justify-content-center align-items-center"
                        href="/addPost"
                        style={{
                          backgroundColor: "#a87c2d",
                          borderColor: "#a87c2d",
                        }}
                      >
                        انشاء منشور جديده +
                      </Button>
                    </Box>

                    <Box
                      sx={{
                        gridColumn: {
                          xs: "span 12",
                          sm: "span 6",
                        },
                      }}
                    >
                      <div className="d-flex justify-content-end align-items-center">
                        <span style={{ marginLeft: "5%" }}> نوع القضيه</span>
                        <select
                          className="filterSelect"
                          onChange={handleCaseTypeChange}
                        >
                          <option value="الكل" selected>
                            الكل{" "}
                          </option>
                          {caseTypesData ? (
                            caseTypesData.map((type) => (
                              <option value={type.attributes.name}>
                                {type.attributes.name}
                              </option>
                            ))
                          ) : (
                            <option value="0">loading...</option>
                          )}
                          
                        </select>
                      </div>
                    </Box>
                  </Box>
                </div>
                <div className="mt-3">
                  <DataGrid
                    rows={data}
                    columns={columns}
                    initialState={{
                      pagination: {
                        paginationModel: {
                          pageSize: 20,
                        },
                      },
                    }}
                    pageSizeOptions={[20]}
                    checkboxSelection
                    disableRowSelectionOnClick
                    className="fs-6 fw-medium"
                    getRowHeight={(params) => {
                      const numCards = params.model.caseTypes.data.length;
                      const cardHeight = 40;
                      return Math.max(numCards * cardHeight, 100);
                    }}
                  />
                </div>

                {/* {-------update modal-------} */}
                <Modal
                  className="d-flex justify-content-center align-items-center"
                  show={showUpdateModal}
                  onHide={handleUpdateModalClose}
                >
                  <Modal.Header closeButton>
                    <Modal.Title className="modal-title ">
                      تعديل المنشور
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Row dir="rtl">
                      <Col sm={12}>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>اسم المنشور</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder={postName}
                            onChange={(e) => setPostName(e.target.value)}
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={12}>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlTextarea1"
                        >
                          <Form.Label>وصف المنشور</Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder={postDescription}
                            onChange={(e) => setPostDescription(e.target.value)}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Modal.Body>
                  <Modal.Footer>
                    <button
                      className="close-btn"
                      onClick={handleUpdateModalClose}
                    >
                      الغاء
                    </button>
                    <button className="save-btn" onClick={handleUpdatePost}>
                      حفظ
                    </button>
                  </Modal.Footer>
                </Modal>
              </div>
            </Box>
          </Box>
          <Box gridColumn="span 2">
            <Sidebar />
          </Box>
          <Box gridColumn="span 11">
            <Footer />
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default Posts;
