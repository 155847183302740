import React, { useState, useEffect } from "react";
import { Box} from "@mui/material";
import Navbar from "../../components/navbar";
import Sidebar from "../../components/sidebar";
import Footer from "../../components/footer";
import {Button} from "react-bootstrap";
import {Row,Col,Alert,ToastContainer} from "react-bootstrap";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Toast from "react-bootstrap/Toast";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import ErrorIcon from "@mui/icons-material/Error";
import profileImage from "../../Assets/images/profile placholder image.jpg";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import InsertLinkOutlinedIcon from "@mui/icons-material/InsertLinkOutlined";
import "./legalIssues.css";
import {useSelector } from "react-redux";
import {selectUserToken} from "../../store/authSlice";
import CaseInfo from "../../components/case-details-components/caseInfo";
import CaseBatchs from "../../components/case-details-components/caseBatchs";
import CaseAttachments from "../../components/case-details-components/caseAttachments";
import axios from "axios";
import ErrorComponent from '../../components/errorComponet/errorComponent'
import { useParams } from "react-router-dom";
const CaseDetails = () => {
  const { id } = useParams();
  const [data, setdata] = useState([]);
  const [error, setError] = useState("");
  const [userName, setUserName] = useState("");
  const [activeTab, setActiveTab] = useState(1);
  const [showToast, setShowToast] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [toastValue, setToastValue] = useState("");
  const [toastColor, setToastColor] = useState("");
  const [toastIcon, setToastIcon] = useState(true);

  const token = useSelector(selectUserToken);

  useEffect(() => {
    getCaseByID();
  }, []);

  const getCaseByID = async () => {
    try {
      const res = await axios.get(
        process.env.REACT_APP_API_URL +
          `/cases/${id}?populate[0]=case_type&populate[1]=user.image&populate[2]=case_status&populate[3]=attachments&populate[4]=attachments.attachmentFile`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const result = customizeDataLevels([res.data.data]);
      setdata(result);
      setUserName(result[0].userName);
    } catch (error) {
      setShowToast(true);
      setToastValue("يوجد مشكله فى ايجاد بيانات القضيه");
      setToastColor("danger");
      setToastIcon(false);
      setError("Error in get user info : " + error);
    }
  };

  const customizeDataLevels = (data) => {
    const formattedData = data.map((item) => {
      // condtion to handle user image if user not have image
      let userImage = item.attributes.user.data.attributes.image.data;
      console.log(process.env.REACT_APP_URL+item.attributes.user.data.attributes.image.data.attributes.url)
      if (userImage === null) {
        userImage = profileImage;
      } else {
        userImage =
          process.env.REACT_APP_URL+item.attributes.user.data.attributes.image.data.attributes.url;
      }
      return {
        id: item.id,
        caseTitle: item.attributes.title,
        description: item.attributes.description,
        caseDate: item.attributes.nextSessionDate.substring(0, 10),
        caseType: item.attributes.case_type.data.attributes.name,
        caseTypeID: item.attributes.case_type.data.id,
        caseState: item.attributes.case_status,
        userName: item.attributes.user.data.attributes.username,
        userEmail: item.attributes.user.data.attributes.email,
        user: item.attributes.user,
        userImage: userImage,
        attachments: item.attributes.attachments,
      };
    });
    return formattedData;
  };
  const statusTrueStyle = {
    backgroundColor: "#28C76F29",
    color: "#28C76F",
  };
  const statusFalseStyle = {
    backgroundColor: "#EA545529",
    color: "#EA5455",
  };
  if (error) {
    return  <ErrorComponent error={error}/>;
  }
  return (
    <div>
      <Box sx={{ width: 1, backgroundColor: "rgba(248, 250, 252, 0.7)" }}>
        <Box
          display="grid"
          gridTemplateColumns="repeat(11, 1fr)"
          gap={2}
          sx={{
            width: "98.5%",
            marginLeft: "0.8%",
          }}
        >
          <Box gridColumn="span 9" sx={{ marginTop: "1%" }}>
            <Navbar />
            <Box
              gridColumn="span 9"
              sx={{
                marginTop: "2%",
              }}
            >
              {/* taost message */}
              <Row>
                <Col xs={12}>
                  <ToastContainer dir="rtl">
                    <Toast
                      onClose={() => setShowToast(false)}
                      show={showToast}
                      delay={10000}
                      autohide
                      animation
                      bg={toastColor}
                    >
                      <Toast.Body className="text-white">
                        {toastIcon ? <CheckCircleIcon /> : <ErrorIcon />}
                        <span className="px-2 text-white fs-6">
                          {toastValue}
                        </span>
                      </Toast.Body>
                    </Toast>
                  </ToastContainer>
                </Col>
              </Row>
              <div className="mt-0 text-end" dir="rtl">
                <FileCopyOutlinedIcon
                  sx={{ color: "rgba(36, 36, 36, 0.80)" }}
                />

                <a href="/legalIssues" className="side-text p-0 m-0 me-1">
                  القضايا القانونية
                </a>
                <KeyboardArrowLeftIcon
                  className="mb-1"
                  sx={{ color: "rgba(36, 36, 36, 0.80)" }}
                />

                <span className="main-text m-0">قضيه {userName}</span>
              </div>
              {/* alert message */}

              <Alert
                show={showAlert}
                variant="danger"
                className="w-25"
                dir="rtl"
              >
                <Alert.Heading> انتبه !</Alert.Heading>
                <p>هل انت متاكد من مسح هذا المنشور </p>
                <hr />
                <div className="d-flex justify-content-end">
                  <Button
                    className="mx-2"
                    onClick={() => setShowAlert(false)}
                    variant="outline-secondary"
                  >
                    الغاء
                  </Button>
                  <Button
                    variant="outline-danger"
                  >
                    نعم
                  </Button>
                </div>
              </Alert>
              {/* Main Content container */}
              <div dir="rtl" className="mt-5 m-3">
                <Row>
                  <Col
                    sm={12}
                    md={12}
                    lg={3}
                    className="case-user-details bg-white p-4  rounded-3"
                  >
                    <div className="user-details-header">تفاصيل العميل</div>
                    {data
                      ? data.map(() => (
                          <div>
                            <div className="d-flex flex-column align-items-center justify-content-center mt-5">
                              <img
                                src={data[0].userImage}
                                alt="صوره العميل"
                                className="rounded-3"
                                width={120}
                                height={100}
                              />
                              <span className="userName mt-3">
                                {data[0].userName}
                              </span>
                              <div className="vertical-line mt-4"></div>
                            </div>
                            <div className="user-details mt-4">
                              <div className="mb-3">
                                الاسم : {data[0].userName}
                              </div>
                              <div className="mb-3">
                                ايميل : {data[0].userEmail}
                              </div>
                              <div className="mb-3">
                                الحاله :{" "}
                                {data[0].user.data.attributes.blocked ===
                                true ? (
                                  <span
                                    className="rounded-2 py-1 px-3"
                                    style={statusFalseStyle}
                                  >
                                    {" "}
                                    غير نشط
                                  </span>
                                ) : (
                                  <span
                                    className="rounded-2 py-1 px-3"
                                    style={statusTrueStyle}
                                  >
                                    {" "}
                                    نشط
                                  </span>
                                )}
                              </div>
                              <div className="mb-2">رقم : {data[0].id}</div>
                            </div>
                          </div>
                        ))
                      : ""}
                  </Col>
                  <Col sm={12} md={12} lg={9} className="px-3 ps-5">
                    <div className="tabs">
                      <button
                        className={activeTab === 1 ? "tab active" : "tab"}
                        onClick={(e) => setActiveTab(1)}
                      >
                        تفاصيل القضية <PersonOutlineOutlinedIcon />
                      </button>
                      <button
                        className={activeTab === 2 ? "tab active" : "tab"}
                        onClick={(e) => setActiveTab(2)}
                      >
                        الدفعات <Inventory2OutlinedIcon />
                      </button>
                      <button
                        className={activeTab === 3 ? "tab active" : "tab"}
                        onClick={(e) => setActiveTab(3)}
                      >
                        الطلبات <InsertLinkOutlinedIcon />
                      </button>
                    </div>
                    <div className="tab-content mt-4  rounded-3">
                      {data.length !== 0 ? (
                        <>
                          {activeTab === 1 && <CaseInfo id={id} data={data} />}

                          {activeTab === 2 && <CaseBatchs id={id} />}

                          {activeTab === 3 && (
                            <CaseAttachments
                              id={id}
                              data={data[0].attachments}
                            />
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            </Box>
          </Box>
          <Box gridColumn="span 2">
            <Sidebar />
          </Box>
          <Box gridColumn="span 11">
            <Footer />
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default CaseDetails;
