import React, { useEffect, useState } from "react";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import { Button } from "react-bootstrap";
import Alert from "react-bootstrap/Alert";
import ErrorIcon from "@mui/icons-material/Error";
import { saveAs } from "file-saver";
import {useSelector } from "react-redux";
import {selectUserToken} from "../../store/authSlice";
import axios from "axios";
import "./caseDetailsComponent.css";

const CaseAttachments = ({ id, data }) => {
  const [inputValue, setInputValue] = useState("");
  const [attachments, setAttachments] = useState([]);
  const [attachmentsData, setAttachmentsData] = useState([]);
  const [attachmentID, setAttachmentID] = useState("");
  const [error, setError] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [toastValue, setToastValue] = useState("");
  const [toastColor, setToastColor] = useState("");
  const [toastIcon, setToastIcon] = useState(true);
  const token = useSelector(selectUserToken);

  useEffect(() => {
    customizeDataLevels(data);
  }, []);

  const updateCase = async () => {
    try {
      if (attachments.length !== 0) {
        const combinedAttachments = [...attachmentsData, ...attachments];
        const allAttachments = combinedAttachments.map((item) => {
          if (item.attachmentFile === false) {
            return {
              attachmentType: item.attachmentType,
            };
          } else {
            return {
              attachmentType: item.attachmentType,
              attachmentFile: item.imageID,
            };
          }
        });
        await axios.put(
          `${process.env.REACT_APP_API_URL}/cases/${id}`,
          {
            data: {
              attachments: allAttachments,
            },
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setShowToast(true);
        setToastValue("تم اضافه الملفات", id);
        setToastColor("success");
        setToastIcon(true);
        setAttachments([]);
      } else {
        setShowToast(true);
        setToastValue("لم تتم العمليه برجاء ادخال جميع البيانات ", id);
        setToastColor("danger");
        setToastIcon(false);
      }
    } catch (error) {
      setShowToast(true);
      setToastValue(":يوجد مشكله فى اضافه الملفات الجديده لقضيه رقم", id);
      setToastColor("danger");
      setToastIcon(false);
      setError("Error Occured in update attachments: " + error);
    }
  };

  const customizeDataLevels = (data) => {
    const formattedData = data.map((item, index) => {
      // condtion to handle user image if user not have image
      let attachmentImage = item.attachmentFile.data;
      let date = item.attachmentFile.data;
      let imageName = item.attachmentFile.data;
      let imageID = item.attachmentFile.data;

      if (attachmentImage !== null) {
        attachmentImage =
          process.env.REACT_APP_URL +
          item.attachmentFile.data[0].attributes.url;
        date = item.attachmentFile.data[0].attributes.updatedAt.substring(
          0,
          10
        );
        imageName = item.attachmentFile.data[0].attributes.name;
        imageID = item.attachmentFile.data[0].id;
      } else {
        attachmentImage = false;
        date = false;
        imageName = false;
        imageID = false;
      }

      return {
        id: index,
        attachmentType: item.attachmentType,
        attachmentFile: attachmentImage,
        date: date,
        imageName: imageName,
        imageID: imageID,
      };
    });
    setAttachmentsData(formattedData);
    return formattedData;
  };
  const deleteAttachment = async () => {
    try {
      setShowAlert(false);
      const updatedAttachments = [...attachmentsData];
      updatedAttachments.splice(attachmentID, 1);
      setAttachmentsData(updatedAttachments);
      const allAttachments = updatedAttachments.map((item) => {
        if (item.attachmentFile === false) {
          return {
            attachmsaentType: item.attachmentType,
          };
        } else {
          return {
            attachmentType: item.attachmentType,
            attachmentFile: item.imageID,
          };
        }
      });
      await axios.put(
        `${process.env.REACT_APP_API_URL}/cases/${id}`,
        {
          data: {
            attachments: allAttachments,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setShowToast(true);
      setToastValue("تم مسح الملف ");
      setToastColor("success");
      setToastIcon(true);
      setAttachmentID("");
    } catch (error) {
      setShowToast(true);
      setToastValue("يوجد مشكله لم تتم عمليه مسح الملف ");
      setToastColor("danger");
      setToastIcon(ErrorIcon);
      setError("Error Occured in delete attachment: " + error);
    }
  };
  const handleDelete = (attachmentID) => {
    setAttachmentID(attachmentID);
    setShowAlert(true);
  };
  // handle attachments feild
  const handleChange2 = (event) => {
    setInputValue(event.target.value);
  };
  const handleSubmit = () => {
    if (inputValue.trim() !== "") {
      setAttachments([
        ...attachments,
        { attachmentType: inputValue.trim(), attachmentFile: false },
      ]);
      setInputValue("");
    }
  };
  const handleRemove = (index) => {
    const updatedAttachments = [...attachments];
    updatedAttachments.splice(index, 1);
    setAttachments(updatedAttachments);
  };
  const handleDownload = (imageURL, imageName) => {
    saveAs(imageURL, imageName);
  };

  if (error) {
    return <div className="fw-semibold fs-5 text-danger">{error}</div>;
  }
  return (
    <>
      <div className="container bg-white p-4 ">
        <div className="case-info-container">
          {/* taost message */}
          <Row>
            <Col xs={12}>
              <ToastContainer
                dir="rtl"
                className="position-absolute"
                style={{ top: "110px", left: "5px" }}
              >
                <Toast
                  onClose={() => setShowToast(false)}
                  show={showToast}
                  delay={10000}
                  autohide
                  animation
                  bg={toastColor}
                >
                  <Toast.Body className="text-white">
                    {toastIcon ? <CheckCircleIcon /> : <ErrorIcon />}
                    <span className="px-2 text-white fs-6">{toastValue}</span>
                  </Toast.Body>
                </Toast>
              </ToastContainer>
            </Col>
          </Row>
          {/* alert message */}

          <Alert
            show={showAlert}
            variant="danger"
            className="w-25 position-absolute"
            style={{ top: "110px", left: "5px" }}
            dir="rtl"
          >
            <Alert.Heading> انتبه !</Alert.Heading>
            <p>هل انت متاكد من مسح هذا الملف </p>
            <hr />
            <div className="d-flex justify-content-end">
              <Button
                className="mx-2"
                onClick={() => setShowAlert(false)}
                variant="outline-secondary"
              >
                الغاء
              </Button>
              <Button onClick={deleteAttachment} variant="outline-danger">
                نعم
              </Button>
            </div>
          </Alert>
          <div className="case-info-title px-4 mb-4">ارسال طلبات</div>
          <div className="px-4">
            <div className="attachments-name-container mb-3">
              {attachments.map((attachment, index) => (
                <div key={index}>
                  <button onClick={() => handleRemove(index)}>x</button>

                  {attachment.attachmentType}
                </div>
              ))}
            </div>
            <div>
              <div>
                <button
                  className="batch-btns rounded-end px-3 py-2 "
                  onClick={handleSubmit}
                >
                  حفظ
                </button>
                <input
                  className="attachment-input rounded-start py-2 px-2 w-75"
                  type="text"
                  value={inputValue}
                  onChange={handleChange2}
                  placeholder="اكتب الطلب"
                  dir="rtl"
                />
              </div>

              <button
                className="batch-btns rounded-2 px-4 py-2 d-block mt-4 "
                onClick={() => {
                  updateCase();
                }}
              >
                ارسال
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="container bg-white p-4">
        <div className="case-info-container ">
          <div className="case-info-title px-4 mb-4">الطلبات السابقة</div>
          <div className="px-4">
            <table class="table border">
              <thead class="table-secondary">
                <tr>
                  <th scope="col"></th>
                  <th scope="col">اسم الطلب</th>
                  <th scope="col">تاريخ الرفع</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {attachmentsData.length !== 0 ? (
                  attachmentsData.map((item) => (
                    <tr>
                      <th scope="row">
                        {item.attachmentFile === false ? (
                          "لم يتم الرفع"
                        ) : (
                          <CloudDownloadOutlinedIcon
                            onClick={() => {
                              handleDownload(
                                item.attachmentFile,
                                item.imageName
                              );
                            }}
                            style={{
                              cursor: "pointer",
                              color: "rgba(36, 36, 36, 0.7)",
                            }}
                          />
                        )}
                      </th>
                      <td>{item.attachmentType}</td>
                      <td scope="row">
                        {item.date === false ? "-" : item.date}
                      </td>
                      <td>
                        <DeleteOutlineIcon
                          sx={{
                            color: "rgba(36, 36, 36, 0.7)",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            handleDelete(item.id);
                          }}
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr className="w-100 d-flex justify-content-center align-items-center">
                    <th>لا يوجد ملفات</th>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default CaseAttachments;
