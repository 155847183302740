import React, { useState } from "react";
import {Row,Col} from "react-bootstrap";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import {useSelector } from "react-redux";
import {selectUserToken} from "../../store/authSlice";
import ErrorComponent from '../../components/errorComponet/errorComponent'
import axios from "axios";

const CardComponent = ({data}) => {
 
const [showInput, setShowInput] = useState(false);
const [meetingLink,setMeetingLink]=useState("");
const [showToast, setShowToast] = useState(false);
const [toastValue, setToastValue] = useState("");
const [toastColor, setToastColor] = useState("");
const [toastIcon, setToastIcon] = useState(true);
const [error,setError]=useState("")
const token = useSelector(selectUserToken);
const regex = /(https?:\/\/www\.|https?:\/\/)?[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})(\.[a-zA-Z0-9]{2,})?/;

const handleRejected =async ()=>{
 try{
  await axios.put(
    `${process.env.REACT_APP_API_URL}/appointment-requests/${data.id}`,
    {
      data: {
        action:"rejected"
      },
     
    },
    {
      headers:{
        Authorization:`Bearer ${token}`
      }
    }
  )
  setShowToast(true);
  setToastValue("تم رفض الطلب");
  setToastColor("success");
  setToastIcon(true);
  window.location.reload();
 }catch(error){
  setError("Error occured in reject the request: "+error)
 }

}
const addAppoitmnet = async() =>{
try{
  const startime= handle24HRFormat(data.time,data.ampm);
  const endTime= convertTo24HRFormat(data.time,data.ampm);
   if (regex.test(meetingLink)) {
         await axios.put(
          `${process.env.REACT_APP_API_URL}/appointment-requests/${data.id}`,
          {
            data: {
              action:"accepted"
            },  
          },
          {
            headers:{
              Authorization:`Bearer ${token}`
            }
          }
        )

        const res= await axios.get(

          `${process.env.REACT_APP_API_URL}/work-days?populate[0]=bookedHours&filters[date][$eq]=${data.date}`,
          {
            headers:{
              Authorization:`Bearer ${token}`
            }
          }
        )
   
        const bookedHours=res.data.data[0].attributes.bookedHours
        const newbookedHours=[...bookedHours,{startTime:startime,
          endTime:endTime}]
        await axios.put(

          `${process.env.REACT_APP_API_URL}/work-days/${res.data.data[0].id}`,
          {
            data: {
              bookedHours:newbookedHours
            },
           
          },
          {
            headers:{
              Authorization:`Bearer ${token}`
            }
          }
        )
      
        setShowToast(true);
        setToastValue("تم انشاء موعد جديد");
        setToastColor("success");
        setToastIcon(true);

        window.location.reload();
      } else {

      
        setShowToast(true);
        setToastValue("لم تتم العمليه برجاء ادخال رابط صحيح");
        setToastColor("danger");
        setToastIcon(false);
      }


}catch(error){
  setError("Error occured in creating appoitment: "+error)
}


}
// handle time for starting time
const handle24HRFormat=(time,status)=>{

  const [hourStr, minuteStr] = time.split(':');
  let hour = parseInt(hourStr);
  const minute = parseInt(minuteStr);

  if (status === 'مساء' && hour !== 12) {
    hour = parseInt(hour, 10);
    hour += 12;
  } else if (status === 'صباحا' && hour === 12) {
    hour = "0";
  }
  const formattedHour = hour < 10 ? '0' + hour : hour;
  const formattedMinute = minute < 10 ? '0' + minute : minute;
  return `${formattedHour}:${formattedMinute}:00`
}
// handle time for ending time
function convertTo24HRFormat(time,status) {
  const [hourStr, minuteStr] = time.split(':');
  let hour = parseInt(hourStr);
  const minute = parseInt(minuteStr);



  if (status === 'مساء' && hour !== 12) {
    hour += 12;
  } else if (status === 'صباحا' && hour === 12) {
    hour = 0;
  }

  hour+=1
  if(hour===13 && status === 'صباحا' ){
    hour=1
  }

  const formattedHour = hour < 10 ? '0' + hour : hour;
  const formattedMinute = minute < 10 ? '0' + minute : minute;


  return `${formattedHour}:${formattedMinute}:00`;
}


  const handleClick = () => {
    setShowInput(true);
  };
  if(error){
    return <ErrorComponent error={error}/>
  }
  return (
    <div className="d-flex justify-content-center w-100 position-relative">
      {/* taost message */}
      <Row>
                <Col xs={12}>
                  <ToastContainer dir="rtl"  className="position-absolute"
                style={{ bottom: "250px", left: "5px" }}>
                    <Toast
                      onClose={() => setShowToast(false)}
                      show={showToast}
                      delay={10000}
                      autohide
                      animation
                      bg={toastColor}
                    >
                      <Toast.Body className="text-white">
                        {toastIcon ? <CheckCircleIcon /> : <ErrorIcon />}
                        <span className="px-2 text-white fs-6">
                          {toastValue}
                        </span>
                      </Toast.Body>
                    </Toast>
                  </ToastContainer>
                </Col>
              </Row>
      <div className="card-container mb-5" dir="rtl">
        <Row>
          <Col sm={12} md={12} lg={8} className="card-content">
            <Row dir="rtl" className="card-header-container px-4 pb-3">
              <Col sm="auto" className="title fs-4 ps-1">
                {data.userName}      
              </Col>
              <Col sm="auto" className="title ps-1 m-0">
              {data.userPhone}
              </Col>
              <Col sm="auto" className="title">
              {data.userEmail}
              </Col>
            </Row>

            <Row dir="rtl" className="card-header-container px-4 pb-2">
              <Col sm="auto" className="title ps-1">
                {data.time}{" "}
              </Col>
              <Col sm="auto" className="title p-0 m-0">
                {data.ampm}{" "}
              </Col>
              <Col sm="auto" className="title">
                {data.date}
              </Col>
            </Row>

            <Row dir="rtl" className="card-header-container px-4 pb-3">
              <Col sm="auto" className="secondTitle ">
                حجز استشارة -
              </Col>
              <Col sm="auto" className="secondTitle p-0 m-0">
                {data.caseTypeName}
              </Col>
              <Col sm="12" className="description  pt-3 m-0">
                {data.caseTypeDescription}
              </Col>
            </Row>
          </Col>
          <Col sm={12} md={12} lg={4} className="card-btns-container" >
        {showInput ? (
            <div className="input-container">
 <button className="border rounded-end" onClick={addAppoitmnet}>رابط الدعوة</button>
        <input className="border rounded-start " type="text" placeholder=" رابط الموعد" onChange={(e)=>{setMeetingLink(e.target.value)}} />
            </div>

           
      ) : (
        <button className="card-btn" onClick={handleClick}>قبول وارسال دعوة</button>
      )}
   
            <button className="card-btn" onClick={handleRejected}>الغاء الطلب</button>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default CardComponent;
