import React from "react";
import "./caseDetailsComponent.css";

const CaseInfo = ({ id, data}) => {
  const statusTrueStyle = {
    backgroundColor: "#28C76F29",
    color: "#28C76F",
  };
  const statusFalseStyle = {
    backgroundColor: "#EA545529",
    color: "#EA5455",
  };

  return (
    <section className="container bg-white p-4">
      <div className="case-info-container ">
        <div className="case-info-title px-4 mb-4">تفاصيل القضيه</div>
        {data.length !== 0 ? (
          <div>
            {" "}
            <div className="info px-4 mb-3">
              اسم القضية :
              <span className="info-data m-0 me-2">{data[0].caseTitle}</span>
            </div>
            <div className="info px-4 mb-3">
              نوع القضية :
              <span className="info-data m-0 me-2">{data[0].caseType}</span>
            </div>
            <div className="info px-4 mb-3">
              وصف القضية :
              <span className="info-data m-0 me-2">{data[0].description}</span>
            </div>
            <div className="info px-4  mb-3 ">
              حاله القضية :
              {data[0].caseState.data.attributes.name === "انتهت"? <span
                className="px-3 py-1 m-0 me-2 rounded-3"
                style={statusFalseStyle}
              >
               { data[0].caseState.data.attributes.name}
              </span>:<span
                className="px-3 py-1 m-0 me-2 rounded-3"
                style={statusTrueStyle}
              >
               { data[0].caseState.data.attributes.name}
              </span>}
             
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </section>
  );
};

export default CaseInfo;
