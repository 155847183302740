import React, { useState, useEffect } from "react";
import Navbar from "../../components/navbar";
import Sidebar from "../../components/sidebar";
import { Search } from "@mui/icons-material";
import Footer from "../../components/footer";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import BlockIcon from "@mui/icons-material/Block";
import { DataGrid } from "@mui/x-data-grid";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import closeIcon from "../../Assets/images/Close.png";
import { Form, Button, Modal,Toast,ToastContainer,Alert,Container ,Row,Col} from "react-bootstrap";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import {Avatar,Typography,ListItem, Box, IconButton } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import profileImage from "../../Assets/images/profile placholder image.jpg"
import "./users.css";
import {useSelector } from "react-redux";
import {selectUserToken} from "../../store/authSlice";
import axios from "axios";
import ErrorComponent from '../../components/errorComponet/errorComponent'

const Users = () => {
  const [data, setdata] = useState([]);
  const [allData, setAllData] = useState([]);
  const [userEmail, setUserEmail] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const [userAddress, setUserAddress] = useState("");
  const [creationError, setCreationError] = useState("");
  const [error, setError] = useState("");
  const [searchValue, setSearchValue] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [toastValue, setToastValue] = useState("");
  const [toastColor, setToastColor] = useState("");
  const [toastIcon, setToastIcon] = useState(true);
  const [showAlert, setShowAlert] = useState(false);


  
  useEffect(() => {
    getUser();
  }, []);

  const token = useSelector(selectUserToken);

  // this part handle add new user modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const createUser =async ()=>{
    try{
      await  axios.post(
                `${process.env.REACT_APP_API_URL}/auth/local/register`,
                {
                  username: username,
                  email: userEmail,
                  password: password,
                  phone: userPhone,
                  address: userAddress,
                },
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              );
              setShowToast(true);
              setToastValue("تم اضافة المستخدم");
              setToastColor("success");
              setToastIcon(true);
    setUsername("");
      setUserEmail("");
      setPassword("");
      setUserPhone("");
      setUserAddress("");

    }catch(error){
      setCreationError("يوجد مشكله فى هذا الطلب الرجاء التاكد من ادخال كل البيانات صحيحه")

    }
  }
 
  const handleDelete = async (id) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/users/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setShowToast(true);
      setToastValue("تم مسح المستخدم");
      setToastColor("success");
      setToastIcon(true);
    
    } catch (error) {
      
      setShowToast(true);
      setToastValue("يوجد مشكله لم يتم مسح المستخدم");
      setToastColor("success");
      setToastIcon(true);
    }
  };

  const handleBlock = async (id) => {
    try {
      const user=await axios.get(
        `${process.env.REACT_APP_API_URL}/users/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      const userState=user.data.blocked;
      let  updatedUser= { ...user.data, blocked: true }; 
      if(userState){
        updatedUser= { ...user.data, blocked: false }; 
      }
    await axios.put(
        `${process.env.REACT_APP_API_URL}/users/${id}`,updatedUser,
       
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setShowToast(true);
      setToastValue("تم تعديل حاله المستخدم");
      setToastColor("success");
      setToastIcon(true);
    } catch (error) {
    
      setError("Error occured when updag=ting user state",error);
    }
   
  };
  const customizeDataLevels = (data) => {
    const formattedData = data.map((item) => {
     
      // condtion to handle user image if user not have image
      let userImage=item.image;
      if(userImage === null){
        userImage =profileImage
      }else{
        userImage=process.env.REACT_APP_URL + item.image.url;
      }
      return {
        id: item.id, 
        userInfo: [
          item.email,
          userImage,
          item.username,
        ],
        userName: item.username, 
        address: item.address, 
        blocked: item.blocked, 
        createdAt: item.createdAt.substring(0, 10), 
        email: item.email, 
        phone: item.phone, 
        appointments: item.appointments.length,
        cases: item.cases.length, 
        confirmed: item.confirmed, 
        appointment_requests: item.appointment_requests.length, 
        role: item.role.name,
        image: userImage, 
      };
    });
   
    return formattedData;
  };
 
  const getUser = async () => {
    try {
      const res = await axios.get(
        process.env.REACT_APP_API_URL +
          "/users?filters[role][name][$ne]=admin&populate[0]=likes&populate[1]=appointments&populate[3]=appointment_requests&populate[4]=image&populate[5]=role&populate[6]=cases",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      customizeDataLevels(res.data);
      setdata(customizeDataLevels(res.data));
      setAllData(customizeDataLevels(res.data));
    } catch (error) {
      setError("Error occured in getting user data"+error);
    }
  };

  const DataFilteredByUserEmail = (email) => {
    if (email === "") {
      setdata(allData);
    } else {
      const filteredData = allData.filter((item) => item.email === email);
      setdata(filteredData);
    }
  };

  const DataFilteredByUserState = (state) => {
    if (state === "الكل") {
      setdata(allData);
    } else {
      if(state === "0"){
        setdata(allData.filter((item) => item.blocked === false));
      }else if(state === "1"){
        setdata(allData.filter((item) => item.blocked === true));
      }
      
    }
  };
 
  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 10,
    },
    {
      field: "userInfo",
      headerName: "بينات العميل",
      width: 260,
      renderCell: (params) => {
        return (
          <>
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <Avatar alt="Remy Sharp" src={params.row.userInfo[1]} />
              </ListItemAvatar>
              <ListItemText
                sx={{
                  textAlign: "start",
                }}
                primary={
                  <Typography
                    sx={{
                      fontSize: "15px",
                      fontWeight: "700",
                      fontFamily: "'Noto Sans Arabic', sans-serif",
                      color: "rgba(63, 63, 63, 0.8)",
                    }} 
                    component="span"
                  >
                    {params.row.userInfo[2]}
                  </Typography>
                }
                secondary={
                  <React.Fragment>
                    <Typography
                      sx={{
                        color: "rgba(150, 149, 147, 0.8)",
                        fontSize: "11px",
                      }}
                      component="div"
                      variant="body2"
                    >
                      {params.row.userInfo[0]}
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItem>
          </>
        );
      },
    },
    {
      field: "blocked",
      headerName: " حاله المستخدم  ",
      width: 120,
      renderCell: (params) => {
        if (params.row.blocked === true) {
          return (
            <>
              <nav className="user-block-state">Bloked</nav>
            </>
          );
        } else {
          return (
            <>
              <nav className="user-unblock-state">unBlock</nav>
            </>
          );
        }
      },
    },
    {
      field: "createdAt",
      headerName: "تاريخ الحساب",
      width: 120,

      editable: true,
    },
    {
      field: "phone",
      headerName: " رقم التليفون",
      width: 150,

      editable: true,
    },
    {
      field: "address",
      headerName: "العنوان",
      width: 150,

      editable: true,
    },
    {
      field: "cases",
      headerName: "عدد القضايا",
      width: 110,

      editable: true,
    },
    {
      field: "appointment_requests",
      headerName: " طلبات اجتماعات",
      width: 110,

      editable: true,
    },

    {
      field: "action",
      headerName: "Action",
      width: 130,
      renderCell: (params) => {
        return (
          <>
            <IconButton
              onClick={() => handleDelete(params.row.id)}
              sx={{ color: "#A87C2D" }}
            >
              {" "}
              <DeleteOutlineIcon />
        
            </IconButton>
            <IconButton
              onClick={() => handleBlock(params.row.id)}
              sx={{ color: "#A87C2D" }}
            >
              {" "}
              <BlockIcon />
            </IconButton>
          </>
        );
      },
    },
  ];

 
  const handleUserState = (event) => {
    DataFilteredByUserState(event.target.value);
  };

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
    DataFilteredByUserEmail(event.target.value);
  };

  // handle create user validations
  const [validated, setValidated] = useState(false);
  const handleCreateSubmit = (event) => {
    let state=true
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      state=false
    }
    const checkEmail = data.filter((item) => item.email === userEmail);
    const checkUserName = data.filter((item) => item.userName === username);
    if(checkEmail.length >0 || checkUserName.length >0){
      setCreationError("اسم المستخدم او البريد الالكترونى مستخدم من قبل ")
      event.preventDefault();
      event.stopPropagation();
      state=false
    }
    if(state){
      createUser();
    }
    setValidated(true);
    
  };

  if (error) {
    // Print errors if any
    return <ErrorComponent error={error}/>;
  }
  return (
    <div>
      <Box sx={{ backgroundColor: "rgba(248, 250, 252, 0.7)" }}>
        <Box
          display="grid"
          gridTemplateColumns="repeat(11, 1fr)">
          <Box gridColumn="span 9" className="mx-3 mt-3">
            <Navbar />
            <Box
              gridColumn="span 9"
              className="mt-4"
              dir="rtl"
            >
                 {/* alert message */}
            <Alert show={showAlert} variant="danger"   className="w-25"
                  style={{  right: "75%" }}>
              <Alert.Heading> انتبه !</Alert.Heading>
              <p>هل انت متاكد من مسح هذه القضية</p>
              <hr />
              <div className="d-flex justify-content-end">
                <Button
                  className="mx-2"
                  onClick={() => setShowAlert(false)}
                  variant="outline-secondary"
                >
                  الغاء
                </Button>
                <Button 
                // onClick={deleteCase}
                 variant="outline-danger">
                  نعم
                </Button>
              </div>
            </Alert>

            {/* taost message */}
            <Row>
              <Col xs={12}>
                <ToastContainer
                  dir="rtl"
                  className="position-absolute"
                  style={{ top: "100px", left: "5px" }}
                >
                  <Toast
                    onClose={() => setShowToast(false)}
                    show={showToast}
                    delay={10000}
                    autohide
                    animation
                    bg={toastColor}
                  >
                    <Toast.Body className="text-white">
                      {toastIcon ? <CheckCircleIcon /> : <ErrorIcon />}
                      <span className="px-2 text-white fs-6">{toastValue}</span>
                    </Toast.Body>
                  </Toast>
                </ToastContainer>
              </Col>
            </Row>
              <div
                dir="rtf"
                className="text-end me-2 mb-3" >
                <PeopleOutlineIcon sx={{ color: "rgba(36, 36, 36, 0.80)" }} className="mb-1" />
                <span className="me-2 d-inline-block fw-bold" style={{color: "rgba(36, 36, 36, 0.80)"}}>
                  المستخدمين
                </span>
              </div>

              <div>
                <div>
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)" >
                    <Box
                      sx={{
                        gridColumn: {
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 4",
                          lg: "span 4",
                          xl: "span 3",
                        },
                      }}
                    >
                      <button
                        className="w-75 h-75 fs-5 user-create-btn "
                        href="#"
                        onClick={handleShow}
                      >
                        انشاء قضيه جديده +
                      </button>

                      {/* this part to handel add issue part */}
                      <Modal
                        show={show}
                        onHide={handleClose}
                        backdrop="static"
                        keyboard={false}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                      >
                        <Form
                          noValidate
                          validated={validated}
                          onSubmit={handleCreateSubmit}
                        >
                          <Modal.Body className="d-flex flex-row-reverse">
                            <img
                              src={closeIcon}
                              alt="close icon"
                              onClick={handleClose}
                              style={{
                                cursor: "pointer",
                                position: "absolute",
                                top: "-15px",
                                left: "-15px",
                              }}
                            />
                            <Container
                             className="bg-white m-3"
                              dir="rtl"
                            >
                              <Row  className="mb-3">
                                <Col>
                                <h1 className="user-modal-header">تعديل بيانات مستخدم </h1>

                                </Col>
                              </Row>
                              <Row>
                                <Row className="mb-3">
                                  <Form.Group
                                    as={Col}
                                    md="12"
                                    controlId="validationCustom01"
                                    className="mb-3"
                                  >
                                    <Form.Label> اسم المستخدم</Form.Label>
                                    <Form.Control
                                      required
                                      type="text"
                                      placeholder="اسم المستخدم"
                                      pattern="^[\u0600-\u06FF\u0750-\u077F\uFB50-\uFDFF\uFE70-\uFEFFa-zA-Z0-9\s]{3,}$"
                                      onChange={(e) =>
                                        setUsername(e.target.value)
                                      }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      برجاء ادخال اسم صحيح لا يقل عن 3 حروف و استخدام حروف و ارقام فقط.
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                  <Form.Group
                                    as={Col}
                                    md="12"
                                    controlId="validationCustom02"
                                    className="mb-3"
                                  >
                                    <Form.Label>البريد الالكترونى</Form.Label>
                                    <Form.Control
                                      required
                                      type="email"
                                      placeholder=" البريد الالكترونى"
                                      pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}"
                                      onChange={(e) =>
                                        setUserEmail(e.target.value)
                                      }
                                     
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      برجاء ادخال بريد الكترونى صحيح
                                    </Form.Control.Feedback>
                                  </Form.Group>

                                  <Form.Group
                                   controlId="password"
                                   className="mb-3"
                              
                                   >
                                    <Form.Label>كلمة المرور</Form.Label>
                                    <Form.Control
                                      type="password"
                                      placeholder="كلمة المرور"
                                      pattern="(?=.*[A-Z]).{8,}"
                                      
                                      title="يجب أن تتكون كلمة المرور من على الأقل 8 أحرف وتحتوي على حرف كبير واحد على الأقل"
                                      required
                                      onChange={(e) =>
                                        setPassword(e.target.value)
                                      }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      برجاء إدخال كلمة مرور صالحة (على الأقل 8
                                      أحرف ويجب أن تحتوي على حرف كبير واحد على
                                      الأقل).
                                    </Form.Control.Feedback>
                                  </Form.Group>

                                  <Form.Group
                                    as={Col}
                                    md="6"
                                    controlId="validationCustom04"
                                    className="mb-3"
                                    onChange={(e) =>
                                      setUserPhone(e.target.value)
                                    }
                                  >
                                    <Form.Label> رقم الهاتف</Form.Label>
                                    <Form.Control
                                      type="tel"
                                      placeholder=" رقم الهاتف"
                                      required
                                      pattern="[0-9]+"
                                    />
                                    <Form.Control.Feedback type="invalid">
                                    برجاء إدخال رقم هاتف صحيح (أرقام فقط).
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                  <Form.Group
                                    as={Col}
                                    md="6"
                                    controlId="validationCustom06"
                                    className="mb-3"
                                  >
                                    <Form.Label>عنوان المستخدم</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="  العنوان"
                                      required
                                      onChange={(e) =>
                                        setUserAddress(e.target.value)
                                      }
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      برجاء ادخال العنوان كامل
                                    </Form.Control.Feedback>
                                  </Form.Group>
                               
                                </Row>
                                <Col sm={12}>
                                {creationError ? (
                                  <div className="text-danger fw-bold fs-5">{creationError}</div>
                                ) : (
                                  <div></div>
                                )}
                              </Col>
                              <Col sm={12}>
                              </Col>
                              </Row>
                            </Container>
                          </Modal.Body>
                          <Modal.Footer>
                            <button className="user-modal-close-btn"
                             onClick={handleClose}
                            >
                              الغاء
                            </button>
                            <button type="submit" 
                            className="user-modal-submit-btn">   تسجيل المستخدم </button>
                          </Modal.Footer>
                        </Form>
                      </Modal>
                    </Box>

                    <Box
                      sx={{
                        gridColumn: {
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                          xl: "span 5",
                        },
                      }}
                    >
                      <div className="d-flex w-100 h-100">
                        <div className="search-icon h-75">
                          <Search />
                        </div>
                        <input
                          type="text"
                          className="searchStyle w-100 h-75"
                          placeholder="بحث بالبريد الالكترونى..."
                          value={searchValue}
                          onChange={handleSearchChange}
                        />
                      </div>
                    </Box>

                    <Box
                      sx={{
                        gridColumn: {
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                          xl: "span 4",
                        },
                      }}
                    >
                      <div className="d-flex justify-content-end align-items-center">
                        <span style={{ marginLeft: "5%" }}> حاله المستخدم</span>
                        <select
                          className="filterSelect"
                          onChange={handleUserState}
                        >
                          <option value="الكل" selected>الكل </option>
                          <option value="1">blocked </option>
                          <option value="0">unblocked </option>
                          
                        </select>
                      </div>
                    </Box>
                    
                  </Box>
                </div>
                <div style={{ margin: "2% 0% 2.25% 0%" }}>
                  <DataGrid
                    rows={data}
                    columns={columns}
                    initialState={{
                      pagination: {
                        paginationModel: {
                          pageSize: 20,
                        },
                      },
                    }}
                    pageSizeOptions={[20]}
                    checkboxSelection
                    disableRowSelectionOnClick
                    className="fs-6 fw-medium"
                    // sx={{
                    //   fontSize:'15px',
                    //   fontWeight:'500'
                    // }}
                  />
                </div>
              </div>
            </Box>
          </Box>
          <Box gridColumn="span 2">
            <Sidebar />
          </Box>
          <Box gridColumn="span 11">
            <Footer />
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default Users;
