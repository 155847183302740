import React from 'react'
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import error404 from "../../Assets/images/404.jpg"
import "./errorPage.css"
 const ErrorPage = () => {
    return (

      <div className='d-flex flex-column justify-content-center align-items-center' style={{height:'100vh'}}>
        <img src={error404} alt="404 img" className='errorImg'/>
          <h1 className='text-dark fw-bolder fs-3 error-header'>Page Not Found</h1>  
          <h5 className='mt-2 text-center px-sm-5 px-2 error-text'>We could not find what you were looking for, Please go back to Home Page.</h5>
          <a href='/login' className='error-btn px-4 py-2 mt-3 rounded-3'>Home</a>
          </div>
    )
}
export default ErrorPage
